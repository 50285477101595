.zapier-case-study-page {

    .IntroPara {
        font-size: 20px;
    }
    
    .headerContainer h6 {
        color: #8780E4;
    }

    .headerContainer h1, .headerContainer p {
        color: #3B3E4D;
        @media (min-width: $min-width-md) {
            color: #FFFFFF;
        }
    }

    .tilesContainer {
        .col-12 {
            color: #FFFFFF !important;
        }
        .green-container {
            background-color: #16C39A;
            min-height: 230px;
        }
        .blue-container {
            background-color: #1CA6D0;
            min-height: 230px;
        }
        .purple-container {
            background-color: #638EEB;
            min-height: 230px;
        }
    }

    .green-container {
        background-color: #f4fdfc;
    }

    .purple-container {
        background-color: #5076f1;
    }

    .headerContainer {
        padding: 50px 35px;
        background: none;
        margin-bottom: 0px;

        @media (min-width: $min-width-md) {
            background: url("./../../../../assets/images/case-study/zapier-case-study/Hero-4.png") top center no-repeat;
            background-size: cover;
            padding: 130px 0px 150px 0px;
            margin-bottom: 100px;
            background-position: right center;
        }

        @media (max-width: $max-width-md) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .QuoteContainer {
        background: url("./../../../../assets/images/case-study/zapier-case-study/JanineAnderson_adj-1.png") no-repeat;
        background-size: contain;
        background-position: bottom right;
        background-color: #897FEC;
        margin: 40px 0px;
        padding: 40px 35px 80px 35px;
        @media (min-width: $min-width-sm) {
            background: url("./../../../../assets/images/case-study/zapier-case-study/JanineAnderson_adj-1.png") no-repeat;
            background-size: contain;
            background-position: center bottom;
            padding: 80px 0px;
            margin: 100px 0px;
            background-color: #897FEC;
        }

        @media (max-width: $max-width-md) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .QuoteContainer h3,
    .QuoteContainer h6 {
        color: #ffffff !important;
    }

    .QuoteContainer h6 {
        border-left: 3px solid #68dec2;
        padding: 5px 0px 5px 25px;
        line-height: 32px;
        max-width: 230px;
    }

    .green-highlight {
        background-color: #8eeee0;
        padding: 0px 5px;
        border-radius: 5px;
    }

    .purple-container h1,
    .purple-container p {
        color: #ffffff;
    }

    .green-btn,
    .form-container input[type="submit"],
    .form-container input[type="button"] {
        padding: 14px 40px;
        height: 48px;
        border-radius: 25px;
        background-color: #15c39a;
        border: none;
        color: #ffffff;
        text-decoration: none;
    }

    .zcs-promote-icon{
        max-height: 100px;
        margin-bottom: 30px;
    }

    .ghost-btn {
        padding: 14px 40px;
        height: 48px;
        border-radius: 25px;
        background-color: #ffffff;
        border: none;
        color: #4A6EE0;
        text-decoration: none;
        font-size: 18px;
        @media (min-width: $min-width-sm) {

        }
    }

    a:hover {
        text-decoration: none;
    }

    .blue-container {
        background-color: #4A6EE0;
        color: #FFFFFF;
    }

    .form-container {
        height: fit-content;
        width: 100%;
    }

    .form-container .sources-text {
        color: #ffffff;
    }

    .form-container .form-elements,
    .form-container .selectedBtn {
        display: none;
    }

    .form-container label {
        width: 100%;
        margin-top: 38px;
    }

    .form-container .btn-talk {
        width: auto !important;
    }

    .form-container input,
    .form-container select,
    .form-container textarea {
        box-sizing: border-box;
        border: 1px solid rgba(129, 137, 169, 0.4);
        border-radius: 5px;
        width: 100%;
        height: 45px;
        margin-top: 15px;
        padding: 0px 0px 0px 10px;
    }

    .form-container input[type="submit"],
    .form-container input[type="button"] {
        width: auto;
        margin: 25px 0px 0px 0px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        padding: 0px 80px;
    }

    .Form-Intro-Container p {
        color: #3b3e4d;
        font-family: "AkkuratPro";
        font-size: 16px;
        line-height: 25px;
    }

    .customRadioBtn {
        display: none;
    }

    .customRadioBtn ~ label {
        padding: 10px 0px;
        border-radius: 5px;
        background-color: #ffffff;
        max-width: 90px;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #8189a9;
        display: inline-block;
        cursor: pointer;
        margin-right: 0px;
        margin-top: 0px !important;
        margin-bottom: 0px;
        box-sizing: border-box;
        border: 1px solid rgba(129, 137, 169, 0.4);

        @media (max-width: $max-width-sm) {
            margin-right: 30px;
            margin-top: 20px !important;
        }
    }

    .customRadioBtn:checked ~ label {
        background-color: #3E3D3D;
        color: #ffffff;
        display: inline-block;
    }

    .form-container textarea {
        height: 150px !important;
        padding-top: 10px;
    }

}

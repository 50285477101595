.vox-style-guide-page{
    overflow: hidden;
    .grey-container-gradient{
        background: linear-gradient(173.82deg, #FAFAFA 21.12%, #F7F9FD 89.26%);
    }

    .green-btn{
        border-radius: 6px;
    }

    .header-banner{
        h1{
            margin-bottom: 25px;
        }
        p{
            margin-bottom: 50px;     
        }
    }

    h4{
        .green-text{
            display: inline-block;
            margin-bottom: 10px;
        } 
    }

    .section-icon{
        margin-bottom: 48px!important;
    }
    
    .section-block{
        .section-icon-2{
            margin-top: 80px;
        }
        .green-btn{
            margin-top: 50px;  
        }
    }
    
    .style-guide-section{
        .img-section img{
            box-shadow: 0px 12px 48px rgba(109, 117, 141, 0.2);
            border-radius: 10px;
        }
        /*
        @media (min-width: $min-width-sm) and (max-width: 1139px){
            .img-section img{
                max-width: 150%;
                width: 125%;
                left: -13%;
                position: relative;
            }
        }

        @media (min-width:1140px){
            .img-section img{
                max-width: 100%;
                width: 110%;
                right: -9%;
                position: relative;
            }
        }
        */
    }
    

    .administration-tools-container{
        margin-bottom: 15px;
    }

    .coming-soon-container{
        margin-bottom: 20px;
    }

    .img-stretch-edge{
        .bottom-img-container{
            position: relative;
            margin-top: 50px;
        }
        .bottom-img{
            max-width: 200%;
            width: 140%;
            left: 0;
            top: 0;
        }
    }
    
    

    .sponsor-section-container {
        padding-top: 60px;
        padding-bottom: 100px;

        h3.service-title{
            margin-bottom: 10px;
        }
        h2{
            margin-bottom: 25px;
        }

        p{
            margin-bottom: 65px;
        }

        img{
            margin-bottom: 30px;
        }
        h4{
            margin-bottom: 10px;
        }
        h5{
            margin-bottom: 50px;
        }

        .service-sub-title{
            margin-bottom: 25px;
        }

        .service-sub-img img{
            padding: 0px;
            margin-bottom: 15px;
            height: 25px;
        }
    }

    .join-customer-section{
        padding-top: 90px;
        padding-bottom: 40px;
        img{
            margin-bottom: 0px; 
            margin-right: 35px;  
        }
        .col-4{
            display: flex;
            align-self: center;
        }
    }

    .purple-container{
        padding: 100px 0px;

        background-color: #3659B5;
        h2{
          margin-bottom: 15px;
          margin-top: 15px;
        }
    
        p{
           margin-bottom: 60px;
        }
    } 

   

    @media (min-width:1140px){
        .container {
            max-width: 1140px;
        }
    }

    @media (max-width: $max-width-sm) {
        
        .header-banner{
            h1{
                margin-bottom: 10px;
            }
            p{
                margin-bottom: 35px;     
            }
            .green-btn-ggb{
                display: inline-block;
                margin-bottom: 50px;
                margin-top: 0px!important;
            }
        }

        .join-customer-section{
            img{
                margin-right: 0px;
            }
            .second-row-img{
                margin-top: 25px;
            }
        }
        

        .section-block{
            .section-icon {
                margin-bottom: 15px !important;
            }
            h4{
                margin-bottom: 35px!important;
            }
            .edge-stretch-mob{
                max-width: 150%;
                width: 130%;
                left: -8%;
                position: relative;
            }
            .section-icon-2 {
                margin-top: 60px;
            }
        }
        .style-guide-section{
            .edge-stretch-mob{
                left: 0px;   
            }   
        }


        .reporting-section{
            .green-btn{
                width: auto;
                margin-top: 0px!important;
                margin-bottom: 50px!important;
            }
            .bottom-img-container{
                margin-top: 30px;
            }
        }

        .green-btn-auto-mb{
            width: auto;
        }

        .join-customer-section{
            padding-top: 50px;
        }

        .service-sub-img{
            .second-row-img{
                margin-top: 20px;
            }
        }

        .purple-container{
            padding: 50px 0px;

            text-align: center;
            h2{
                margin-top: 0px;
            }
            p{
                margin-bottom: 40px;
            }
            .green-btn{
                margin-bottom: 55px!important;
            }

            .img-section{
                padding: 0px;
            }
            img{
                max-width: 105%;
                width: 105%;
                margin-left: -5%;
            }
        }
    }

}   
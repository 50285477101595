.blind-case-study-page {
    
    .headerContainer {
        padding: 100px 0px 80px;
        margin-bottom: 80px;
        @media (min-width: $min-width-md) {
            background: #1F243C;
        }
    }
    
    
    .QuoteContainer{
        background-image: none;
        margin: 50px 0px 70px 0px;
    }

    .key-to-success-section{
        img{
            height: 98px;
            width: auto;
        }
    }
    .banner-hero-image{
        max-width: 400px;
        width: 100%;
    }

    

}
.container-type-2-custom{
    padding: 30px;

    h1{
        padding-top: 25px;
        padding-bottom: 20px;
        margin-bottom: 0px;
    }

    .txt-section{
        display: flex;
        flex-direction: column;

        .txt-copy{
            flex-grow: 3;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}
.tiles-section-type-2-custom{
    .container-type-2-custom{
        padding-left: 25px;
        padding-right: 25px;
    }
}

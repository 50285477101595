.blog-page {

    .IntroPara {
        font-size: 20px;
    }

    p a, p a:hover, p a:visited, p a:focus {
        color: #4A6EE0;
    }

    .container-fluid:first-child{
        background-color: #ffffff;
    }
    
    .sidebar-Container {
        top: 48px;
    }
    .light-purple {
        color: #A6ADC9;
    }
    .green-btn,
    .form-container input[type="submit"],
    .form-container input[type="button"] {
        padding: 14px 40px;
        height: 48px;
        border-radius: 25px;
        background-color: #15c39a;
        border: none;
        color: #ffffff;
        text-decoration: none;
    }

    .green-container {
        background-color: #f4fdfc;
        border-radius: 10px;
    }

    .green-highlight {
        background-color: #8eeee0;
        padding: 0px 5px;
        border-radius: 5px;
    }

    .ghost-btn {
        padding: 14px 40px;
        height: 48px;
        border-radius: 25px;
        background-color: #ffffff;
        border: none;
        color: #4A6EE0;
        text-decoration: none;
        font-size: 18px;
        @media (min-width: $min-width-sm) {

        }
    }

    a:hover {
        text-decoration: none;
    }

    .blue-container {
        background-color: #4A6EE0;
        color: #FFFFFF;
    }

    .header-section-content {
        min-height: fit-content;
        @media (min-width: $min-width-sm) {
            min-height: 500px;
            padding-bottom: 50px;
        }
    }

    .social-icon-container .col-xs {
        background-color: #F1F2FC;
        border-radius: 50px;
        cursor: pointer;

        &:hover {
            background-color: #FFFFFF;
        }
    }

    .social-icon-container img {
        min-width: 42px;
        min-height: auto;
    }

    .social-icon-divider hr {
        border-color: #3E3D3D;
    }

    .sources-text {
        font-size: 12px;
        line-height: 18px;
        color: #60657B;
    }

    .form-container {
        height: fit-content;
        width: 100%;
    }

    .form-container .sources-text {
        color: #ffffff;
    }

    .form-container .form-elements,
    .form-container .selectedBtn {
        display: none;
    }

    .form-container label {
        width: 100%;
        margin-top: 38px;
    }

    .form-container .btn-talk {
        width: auto !important;
    }

    .form-container input,
    .form-container select,
    .form-container textarea {
        box-sizing: border-box;
        border: 1px solid rgba(129, 137, 169, 0.4);
        border-radius: 5px;
        width: 100%;
        height: 45px;
        margin-top: 15px;
        padding: 0px 0px 0px 10px;
    }

    .form-container input[type="submit"],
    .form-container input[type="button"] {
        width: auto;
        margin: 25px 0px 0px 0px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        padding: 0px 80px;
    }

    .Form-Intro-Container p {
        color: #3b3e4d;
        font-family: "AkkuratPro";
        font-size: 16px;
        line-height: 25px;
    }

    .customRadioBtn {
        display: none;
    }

    .customRadioBtn ~ label {
        padding: 10px 0px;
        border-radius: 5px;
        background-color: #ffffff;
        max-width: 90px;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #8189a9;
        display: inline-block;
        cursor: pointer;
        margin-right: 0px;
        margin-top: 0px !important;
        margin-bottom: 0px;
        box-sizing: border-box;
        border: 1px solid rgba(129, 137, 169, 0.4);

        @media (max-width: $max-width-sm) {
            margin-right: 30px;
            margin-top: 20px !important;
        }
    }

    .customRadioBtn:checked ~ label {
        background-color: #3E3D3D;
        color: #ffffff;
        display: inline-block;
    }

    .form-container textarea {
        height: 150px !important;
        padding-top: 10px;
    }

    &.blog-video-page{
        .bvp-section{
            padding: 70px 0px 100px;
        }

        h2{
            max-width: 80%;
            margin: 0px auto 30px;
        }
        p{
            max-width: 80%;
            margin: 0px auto;   
        }
        .iframe-youtube-section{
            margin-top: 50px;
            position: relative;
        }
        
        @media (max-width: $max-width-sm) {
            h2{
                max-width: 100%;
            }
            p{
                max-width: 100%;
            }  
            .iframe-youtube-section{
                iframe{
                    height: 300px;
                }
            } 
        }
    }

}



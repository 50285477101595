.navigation-section{
    .nav-link{
        text-transform: uppercase;
    }
    .navbar{
        display: block!important;
        padding: 0;
    }
    @media (min-width: $min-width-md) {
        position: sticky;
        top: 0;
        background-color: $color-white;
        z-index: 9999;

    }
    a:not(.btn){
        color: $font-color !important;
    }
    .dropdown-menu {
        a:not(.btn):active, a:not(.btn):focus{
            background-color: rgba(0, 0, 0, 0.08);
        }
    }

    box-shadow: 0 2px 30px 0 rgba(129,137,169,0.08);
    .nav-item-btn{
        @media (min-width: $min-width-md) {
            margin-left: 50px;
        }
    }
    .navbar-toggler{
        outline: none;
        padding: 0px;
        border-color: transparent !important;
        .close-icon{
            display: none;
        }
    }
    button[aria-expanded="true"], .navbar-toggler:not(.collapsed){
        .close-icon{
            display: block;
        }
        .navbar-icon{
            display: none;
        }
    }
    .banner-logo {
        @media (max-width: $max-width-sm) {
            width: 149px;
        }
    }
    @media (min-width: $min-width-sm) {
        .navbar-nav{
            flex-direction: row;
        }
        .navbar-collapse{
            display: flex!important;
        }
    }
    @media (min-width: $min-width-sm) and  (max-width: $max-width-md) {
        .navbar-nav{
            .nav-item{
                padding: 0px 5px;
            }
        }
    }

    @media (max-width: $max-width-sm) {
        padding: 0px;
        box-shadow: none;
        .nav-logo-section{
            padding: 22px 35px;
            box-shadow: 0 2px 30px 0 rgba(129,137,169,0.08);
        }
        .navbar{
            padding: 0px;

            .navbar-collapse{
                padding: 22px 35px;
            }
            .dropdown-toggle{
                padding-bottom: 0px;
                &:after{
                    display: none;
                }
            }
            .dropdown-menu{
                display: block;
                border: none;
                padding: 15px 0px;
                position: static!important;
                transform: none!important;
                .dropdown-item{
                    padding: 0px 20px;
                    margin-bottom: 5px;
                    &:hover{
                        background: $background-nav-grey;
                    }
                }
            }
        }
    }
}

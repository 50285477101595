.header-testimonial-section{
    overflow: hidden;
}
.header-section{
    h1{
        margin-top: 60px;
        margin-bottom: 40px;
        @media (max-width: $max-width-sm) { 
            word-wrap: break-word;
            margin-top: 40px;
            margin-bottom: 20px;
        }
    } 
    p{
        margin-bottom: 0px;
        @media (min-width: $max-width-md) {
            margin-left: 5px;
        }
    }   
    .btn{
        margin-top: 44px;
        margin-bottom: 126px;
        @media (max-width: $max-width-sm) { 
            margin-bottom: 0px;
        }
    }
    .header-img-section{
        img{
            position: absolute; 
            width: 100%;
            
            // @media (min-width: $min-width-md) { 
            //     width: 400px;
            //     bottom: -135px;
            // }
            
            @media (min-width: $min-width-md) { 
                width: 850px;
                bottom: -205px;
            }
            
            @media (max-width: $max-width-sm) { 
                position: static;
                width: 100%;
                margin-top: 30px;
                margin-bottom: -90px;
            }
        } 
        @media (min-width: $min-width-sm) and (max-width: $max-width-md) { 
            img{
                width: 560px;
                bottom: -105px;
            }
            
        }  
    }
}
.frost-and-sullivan-landing-page {
    @media (min-width: 1020px){
        .container {
            max-width: 1020px;
        }
    }

    .py-50p-custom{
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .py-65p-custom{
        padding-top: 65px;
        padding-bottom: 65px;    
    }
    .pt-65p-custom{
        padding-top: 65px;
    }
    .mb-8p-custom{
        margin-bottom: 8px;   
    }
    @media (min-width: $min-width-xl){
        .w-xl-75per-custom{
            width: 75%;
        }
        .pr-xl-10px-custom{
            padding-right: 10px;
        }
    }

    @media (max-width: $max-width-sm){
        .py-sm-30p-custom{
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
    .light-grey-container{
        background: $background-light-grey;
    }
    .light-blue-container{
        background: $txt-light-blue-color3;
    }

    .hacking-book-section{
        .img-section{
            img{
                width: 100%;
                max-width: 466px;
            }
        }
        @media (max-width: $max-width-sm){
            .container{
                padding-left: 0px;
                padding-right: 0px;
            }  
            .img-section{
                img{
                    margin-bottom: 0px;
                }  
            }
        }
    }

    ul.green-tick{
        list-style: none;
        padding: 0px;
             
        li {

            p{
                position: relative;
                padding-left: 17px;
            }
        }

        li p:before{
            content: "";
            position: absolute;
            left: 2px;
            top: 7px;
            display: inline-block;
            transform: rotate(45deg);
            height: 11px;
            width: 6px;
            border-bottom: 2px solid #78b13f;
            border-right: 2px solid #78b13f;    
        }
        p{
            margin-bottom: 8px;
        }
        @media (max-width: $max-width-sm){
            text-align: center;
            li{
                margin-bottom: 8px;
                p{
                    display: inline;
                    margin-bottom: 0px;
                }
                p:before{
                    top: 3px;   
                }
            }
        }
    }

    .form-section{
        .form-section-wrapper{
            background: $background-light-grey;
            width: 100%;
            max-width: 374px;
            height: 384px;
            margin: auto;
        }
        
        @media (max-width: $max-width-sm){
            .container{
                padding-left: 0px;
                padding-right: 0px;
            }
            h2{
                margin-bottom: 15px;
            }
            ul.green-tick{
                margin-bottom: 30px;    
            }
            .form-section-wrapper{
                max-width: 100%;
            }
        }
    }

    .related-resources-section{
        .rrs-block-tile{
            img{
                width: 100%;    
                max-width: 100%;
                margin-bottom: 30px;
            }
            .resource-title{
                margin-bottom: 10px;   
                
            }
            h3{
                margin-top: 10px;
            }
            .content{
                margin-bottom: 10px;
            }
            
        }
        @media (max-width: $max-width-sm){
            .container{
                padding-left: 0px;
                padding-right: 0px;    
            }
            h2{
                margin-bottom: 20px;
            }
            .rrs-block-section{
                padding-top: 0px!important;
            }
            .rrs-block-tile{
                margin-bottom: 30px;
                &:last-child{
                    margin-bottom: 0px;    
                }

                img{
                    margin-bottom: 15px;    
                }
                h3{
                    margin-bottom: 8px;
                }
                .content{
                    margin-bottom: 10px;
                }
            }
        }
    }
    
    .tilesContainer {
        .col-12 {
            color: #FFFFFF !important;
        }
        .green-container {
            background-color: #16C39A;
            min-height: 230px;
        }
        .blue-container {
            background-color: #1CA6D0;
            min-height: 230px;
        }
        .purple-container {
            background-color: #638EEB;
            min-height: 230px;
        }
    }

    .green-container {
        background-color: #f4fdfc;
    }

    .purple-container {
        background-color: #5076f1;
    }

    .headerContainer {
        padding: 50px 35px;
        background: $color-black-1;
        margin-bottom: 0px;

        img.logo-img{
            max-width: 370px;
            width: 100%;
            margin-bottom: 10px;
            margin-left: 2px;
        }

        img.banner-hero-image{
            max-width: 540px;
            width: 540px;
            @media (max-width: $max-width-xl) {
                width: 100%;
            }
        }
        h1{
            margin-bottom: 10px;
        }
        p{
            margin-bottom: 30px;
        }
        h1, p {
            color: #FFFFFF;
        }

        .btn{
            max-width: 244px;
            border-radius: 6px;
        }

        @media (max-width: $max-width-md) {
            padding-left: 0px;
            padding-right: 0px;
        }
        @media (max-width: $max-width-sm){
            padding: 30px 0px;
            .container{
                padding-left: 0px;
                padding-right: 0px;
            }
            .txt-section{
                padding-left: 35px;
                padding-right: 35px;    
            }
            img.logo-img{
                max-width: 285px;
                display: block;
                margin: auto;
                margin-bottom: 20px;
            }
            p{
                margin-bottom: 12px;
            }
            .btn{
              display: block;
              margin: auto;
              margin-bottom: 48px;
            }
            .banner-hero-image{
                margin-bottom: 0px;
            }
        }
    }

    .QuoteContainer {
        background: url("./../../../../assets/images/case-study/zapier-case-study/JanineAnderson_adj-1.png") no-repeat;
        background-size: contain;
        background-position: bottom right;
        background-color: #897FEC;
        margin: 40px 0px;
        padding: 40px 35px 80px 35px;
        @media (min-width: $min-width-sm) {
            background: url("./../../../../assets/images/case-study/zapier-case-study/JanineAnderson_adj-1.png") no-repeat;
            background-size: contain;
            background-position: center bottom;
            padding: 80px 0px;
            margin: 100px 0px;
            background-color: #897FEC;
        }

        @media (max-width: $max-width-md) {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .QuoteContainer h3,
    .QuoteContainer h6 {
        color: #ffffff !important;
    }

    .QuoteContainer h6 {
        border-left: 3px solid #68dec2;
        padding: 5px 0px 5px 25px;
        line-height: 32px;
        max-width: 230px;
    }

    .green-highlight {
        background-color: #8eeee0;
        padding: 0px 5px;
        border-radius: 5px;
    }

    .purple-container h1,
    .purple-container p {
        color: #ffffff;
    }

    .green-btn,
    .form-container input[type="submit"],
    .form-container input[type="button"] {
        padding: 14px 40px;
        height: 48px;
        border-radius: 25px;
        background-color: #15c39a;
        border: none;
        color: #ffffff;
        text-decoration: none;
    }

    .zcs-promote-icon{
        max-height: 100px;
        margin-bottom: 30px;
    }

    .ghost-btn {
        padding: 14px 40px;
        height: 48px;
        border-radius: 25px;
        background-color: #ffffff;
        border: none;
        color: #4A6EE0;
        text-decoration: none;
        font-size: 18px;
        @media (min-width: $min-width-sm) {

        }
    }

    a:hover {
        text-decoration: none;
    }

    .blue-container {
        background-color: #4A6EE0;
        color: #FFFFFF;
    }

    .form-container {
        height: fit-content;
        width: 100%;
    }

    .form-container .sources-text {
        color: #ffffff;
    }

    .form-container .form-elements,
    .form-container .selectedBtn {
        display: none;
    }

    .form-container label {
        width: 100%;
        margin-top: 38px;
    }

    .form-container .btn-talk {
        width: auto !important;
    }

    .form-container input,
    .form-container select,
    .form-container textarea {
        box-sizing: border-box;
        border: 1px solid rgba(129, 137, 169, 0.4);
        border-radius: 5px;
        width: 100%;
        height: 45px;
        margin-top: 15px;
        padding: 0px 0px 0px 10px;
    }

    .form-container input[type="submit"],
    .form-container input[type="button"] {
        width: auto;
        margin: 25px 0px 0px 0px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        padding: 0px 80px;
    }

    .Form-Intro-Container p {
        color: #3b3e4d;
        font-family: "AkkuratPro";
        font-size: 16px;
        line-height: 25px;
    }

    .customRadioBtn {
        display: none;
    }

    .customRadioBtn ~ label {
        padding: 10px 0px;
        border-radius: 5px;
        background-color: #ffffff;
        max-width: 90px;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #8189a9;
        display: inline-block;
        cursor: pointer;
        margin-right: 0px;
        margin-top: 0px !important;
        margin-bottom: 0px;
        box-sizing: border-box;
        border: 1px solid rgba(129, 137, 169, 0.4);

        @media (max-width: $max-width-sm) {
            margin-right: 30px;
            margin-top: 20px !important;
        }
    }

    .customRadioBtn:checked ~ label {
        background-color: #3E3D3D;
        color: #ffffff;
        display: inline-block;
    }

    .form-container textarea {
        height: 150px !important;
        padding-top: 10px;
    }

}

.blind-case-study-page {

    .headerContainer {
        .banner-hero-image{
            @media (min-width: $min-width-xl){
                max-width: unset;
                width: auto;
                height: 310px;
            }
        }
        @media (max-width: $max-width-md){
            padding-bottom: 0px;
            margin-bottom: 20px;
        }
    }
    .QuoteContainer{
        padding: 60px 0px 50px 0px;
    }

    .green-container{
        background-color: #EBFFFA;
    }
}


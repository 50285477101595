/** top testimonial: testimonial 1 **/
.testimonial-1{
    padding: 45px 0px 54px;
    .fb-img-block{
        img{
            position: relative;
            margin-left: -85px;
            width: 362px;
            @media (max-width: $max-width-sm) {
                width: 100%;
                margin-left: -20px;
            }
        }
    }
}
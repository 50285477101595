/**
 * Whenever the user hits the footer element when scrolling down,
 * the sticky element just disappear with not transition in order to reveal the non sticky mobile section similar
 * to the floating element
 */
.sticky-container {
  // don't apply any transition

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1020;
  background-color: $color-grey;


  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {

    position: initial;
    bottom: initial;
    left: initial;
    right: initial;

  }

}

.sticky-bottom {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
  bottom: 0px;
}

.move-off-screen {
  bottom: -100%;
}
.quontic-case-study-page{
    .headerContainer {
        @media (min-width: $min-width-md) {
            background-image: none;  
            background-color: #152044; 
        }
        
        position: relative;
        &:before{
            position: absolute;
            bottom: 0px;
            content: " ";
            width: 100%;
            height: 21px;
            background: linear-gradient(to right, #15C39A 0%,#15C39A 18%,#3659B5 18%, #3659B5 70%, #D1CEF4 70%, #D1CEF4 100%);
            @media (max-width: $max-width-md) {
                content: none;
            }
        }
        .header-desktop-img{
            max-width: 220px;
            width: 100%;
        }
    }
    
    .header-container-mobile{
        @media (max-width: $max-width-md) {
            background-image: none;  
            background-color: #152044; 
            img{
                max-width: 200px;
                width: 100%;
                margin: auto;
                margin-bottom: 10px;
                display: block;
            }
        }
        position: relative;
        &:before{
            position: absolute;
            bottom: -15px;
            content: " ";
            width: 100%;
            height: 15px;
            background: linear-gradient(to right, #15C39A 0%,#15C39A 18%,#3659B5 18%, #3659B5 70%, #D1CEF4 70%, #D1CEF4 100%);
        }
    }

    .QuoteContainer {
        background-image: url("./../../../../assets/images/case-study/quontic-case-study/img-4.png");
        // background-position: right bottom;
        background-position: 92% bottom;
        background-size: 40%;
        @media (max-width: $max-width-md) {
            background-size: 65%;   
        }
    }

    .three-tiles{
        img{
            @media (min-width: $min-width-sm) {
                height: 70px;
            }
        }
        .heading-type-3{
            @media (min-width: $min-width-sm) {
                min-height: 76px;
                display: block;
            }
        }
    }
    p{
        a{
            color: #4A6EE0;
        }
    }
}
.container-cr{
    padding: 55px 40px 20px;
    height: 100%;
}
.map-holder-section{
  padding: 110px 0px;
  background: url("../../images/map-background.jpg");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  // background-position: center;
  background-repeat: no-repeat;

  .fast-company-section{
    max-width: 481px;
    width: 100%;
    border-radius: 8px;
    background-color: $color-white;
    margin-bottom: 47px;
    padding: 30px 35px 28px;
    .mic-img-section{
      img{
        max-width: 107px;
        @media (max-width: $max-width-md) {
          max-width: 100%;
        }
      }
    }
    .fc-img-section{
      img{
        width: 260px;
        margin-bottom: 30px;
      }
      p{
        margin-bottom: 0px;
      }
    }
  }

  .lc-cisco img{
    width: 71px;
  }
  .lc-dell img{
    width: 63px;
  }
  .lc-expedia img{
    width: 72px;
  }
  .lc-expensify img{
    width: 154px;
  }
  @media (max-width: $max-width-sm) {
    background-attachment: inherit;
    padding: 60px 0px;
    .fast-company-section{
      margin: 40px 0px;
      padding: 20px 19px;
      .fc-img-section{
        img{
          margin-bottom: 15px;
        }
      }
      .mic-img-section{
        img{
          width: 70px;
        }
      }
    }
    .lc-expensify img{
      margin-top: 35px;
    }
    .lc-expedia img{
      margin-top: 35px;
    }
  }
  @media (min-width: $min-width-sm) and (max-width: $max-width-md) {
    .logo-collection{
      img{
        margin: auto;
        display: block;
      }
    }
  }
}

@import "src/variables";
@import "src/fonts";
@import "src/typography";
@import "src/buttons";
@import "src/utils";

@import "src/sections/sections-common";
@import "src/sections/testimonials-top";
@import "src/sections/navigation_section";
@import "src/sections/header_section";
@import "src/sections/banner-sections";
@import "src/sections/all_teams_section";
@import "src/sections/map_holder_section";
@import "src/sections/beyond_grammar_section";
@import "src/sections/accelarate_results_section";
@import "src/sections/help_your_team";
@import "src/sections/testimonials-mid";
@import "src/sections/why-service-team";
@import "src/sections/footer";

@import "src/pages/mother_landing_page";
@import "src/pages/sales_landing_page";
@import "src/pages/hr_landing_page";
@import "src/pages/marketing_landing_page";
@import "src/pages/customer_service_page";
@import "src/pages/lets_talk_page";
@import "src/pages/ebook_landing_page";
@import "src/pages/not_found_page";
@import "src/pages/new_home_page";
@import "src/pages/case_study_home_page";
@import "src/pages/zapier_case_study_page";
@import "src/pages/blog_page";
@import "src/pages/frost_sullivan_page";
@import "src/pages/vox_style_guide_page";
@import "src/pages/blog_resources_list";
@import "src/pages/quontic_case_study";
@import "src/pages/blind_case_study_page";
@import "src/pages/zourna_case_study_page";
@import "src/pages/cpanel_case_study_page";
@import "src/pages/get_in_touch";
@import "src/pages/frost_sullivan_landing_page";

@import "src/sticky";

@import url('./fonts/inter/inter.css');
.whole-page{
  @media (min-width: $outer-container-width)
  {
    // max-width: $outer-container-width;
  }
  margin: auto;
  background: $color-white;
}
.container{
  // overriding bootstrap max-width to 940px as per design
  // max-width: $inner-container-width;
  @media (min-width: $inner-container-width)
  {
    max-width: $inner-container-width;
  }
}

$outer-container-width: 1440px;
$inner-container-width: 960px;

$color-grey: #F9FAFC;
$color-dark-grey: #F1F2FA;
$color-white: #FFF;

$font-color: #3B3E4D;
$font-color-footer: #8189A9;

$h1-color: #1C1E29;
$h2-color: #1c1f36;

$button-background-color: #15C39A;
$button-background-color-hover: #69DEC2;

$button-background-blue-color: #4C7AF1;
$button-background-blue-color-hover: #89a6ee;

$background-nav-grey: #EAEEF2;
$background-light-grey: #F9FAFF;

$txt-blue-color: #4C7AF1;
$txt-blue-color2: #4A6EE0;
$txt-light-blue-color3: #DBEBFF;
$txt-light-grey-1: #9FA6BF;

$color-black-1: #1F243C;

$max-width-xs: 575.98px;
$max-width-sm: 767.98px;  // Small devices (landscape phones, less than 768px)
$max-width-md: 991.98px;  // Medium devices (tablets, less than 992px)
$max-width-xl: 1199.98px; // Large devices (desktops, less than 1200px)



$min-width-xs: 576px; // Small devices (landscape phones, 576px and up)
$min-width-sm: 768px; // Medium devices (tablets, 768px and up)
$min-width-md: 992px; // Large devices (desktops, 992px and up)
$min-width-xl: 1200px; // Extra large devices (large desktops, 1200px and up)


/**
 * Declaring & incuding fonts for later use
 */

/*
 ***************************
 * * * * * * * * * * * * * *
 *        Font Face        *
 * * * * * * * * * * * * * *
 ***************************
 */
 /** Inter is a fallback font for grammarly so loading it via css file provided by inter **/
@import url('./fonts/inter/inter.css');

@font-face {
  font-family: 'Circular';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/SocialONLY_lineto-circular-black.ttf);
}

@font-face {
  font-family: 'CircularStd-Bold';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/CircularStd-Bold.woff2) format("woff2"),url(../fonts/CircularStd-Bold.otf) format("otf");
}

@font-face {
  font-family: 'AkkuratPro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(../fonts/akkurat-regular.woff2) format("woff2"),url(../fonts/akkurat-regular.woff) format("woff"), url(../fonts/AkkuratStd-Regular.otf);
}

@font-face {
  font-family: 'AkkuratPro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(../fonts/akkurat-bold.woff2) format("woff2"),url(../fonts/akkurat-bold.woff) format("woff"), url(../fonts/AkkuratStd-Bold.otf);
}


/*
 *******************************
 * * * * * * * * * * * * * * * *
 *        Font classes         *
 * * * * * * * * * * * * * * * *
 *******************************
 */
.font-family-circular {
  font-family: 'Circular', sans-serif;
}

.font-family-AkkuratPro {
  font-family: 'AkkuratPro', sans-serif;
}

@mixin font-family-Circular{
  font-family: 'Circular', sans-serif;
}

@mixin font-family-Circular-Std-Bold{
  font-family: 'CircularStd-Bold', sans-serif;
}

@mixin font-family-AkkuratPro{
  font-family: 'AkkuratPro', sans-serif;
}

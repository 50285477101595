/**
 * Overriding default bootstrap btn-primary
 */
.btn-primary {
  background-color: $button-background-color;
  border: $button-background-color;
  border-radius: 25px;
  font-size: #{ (18 / 16) }rem;
  line-height: #{ (21 / 16) }rem;

  &:hover, &:active, &:focus {
    background-color: $button-background-color-hover !important;
    box-shadow: none !important;
  }
}
.btn-small-cm{
  font-size: #{ (11 / 16) }rem;
  line-height: #{ (14 / 16) }rem;
  padding: 9px 33px !important;
  color: $color-white !important;
}
.btn-lm{
  padding: 16px 34px;
}
.btn-talk{
  padding: 16px 73px;
  @media (max-width: $max-width-sm) {
    width: 100%;
  }
}
.btn-cta{
  padding: 16px 45px;
  @media (max-width: $max-width-sm) {
    width: 100%;
  }
}
.btn-eBook{
  padding: 16px 45px;
  @media (max-width: $max-width-sm) {
    width: 100%;
  }
}

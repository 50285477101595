.lets-talk-page{
    .form-page-header-section, .thankyou-page-header-section{
        overflow: hidden;
        padding-bottom: 110px;
        @media (max-width: $max-width-sm) {
            padding-bottom: 20px;
        }
    }
    .header-section{
        h1{
            margin-top: 96px;
            margin-bottom: 30px;
            @media (max-width: $max-width-sm) {
                word-wrap: break-word;
                margin-top: 40px;
                margin-bottom: 20px;
            }
        }
        p{
            margin-bottom: 0px;
            @media (min-width: $max-width-md) {
                margin-left: 5px;
            }
        }
        .btn{
            margin-top: 0px;
            margin-bottom: 30px;
            @media (max-width: $max-width-sm) {
                margin-bottom: 0px;
            }
        }

        .dots-background {
            position: absolute;
            top: 20px;
            left: -65px;
            z-index: 1;
            @media (max-width: $max-width-sm) {
                left: 0px;
            }
        }

        .header-img-container {
            position: relative;
            z-index: 2;
        }

        .lets-talk-header-img-container {
            width: 90%;
            position: relative;
            z-index: 2;
        }

        // form Styles
        .form-container {
            height: auto;
            width: 450px;
            margin-top: 80px;
            border-radius: 8px;
            background-color: #FFFFFF;
            box-shadow: 0 2px 27px 0 rgba(129,137,169,0.33), 0 2px 27px 0 rgba(129,137,169,0.33);
            position: relative;
            z-index: 3;
            @media (max-width: $max-width-sm) {
                width: 100%;
                margin-top: 40px;
            }

            label {
                text-transform: uppercase;
                color: #8189A9;
                letter-spacing: 2px;
                line-height: 34px;
                font-size: 14px;
            }

            input {
                border: 1px solid rgba(129,137,169,0.4);
            }
        }

        .form-img-right {
            position: absolute;
            transform: rotate(90deg);
            right: -160px;
            top: 165px;
            z-index: 1;
        }

        .form-img-left {
            position: absolute;
            left: -100px;
            top: 45%;
            z-index: 1;
        }
    }

    .thankyou-page-header-section .header-section .form-container {
        @media (max-width: $max-width-sm) {
            background: transparent;
            box-shadow: none;
            margin-top: 0px;
        }
    }

    .thankyou-page-header-section .header-section .form-img-left {
        position: absolute;
        left: -100px;
        top: 140px;
        z-index: 1;
    }

    .error-msg{
        color: #e2534f;
        display: none;
    }
    .error-msg-show{
        display: block;
    }
    blockquote{
        .inverted-comma{
            margin: 15px 0px;
            width: 31px;
        }
        .blockquote-body{
            color: $font-color-footer;
            margin-bottom: 29px;
            font-style: italic;
        }

        .blockquote-footer-1{
            .author{
                display: block;
                color: $button-background-color;
                font-weight: bold;
            }
            .position{
                display: block;
                color: $font-color-footer;
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }
    }

    .continer-show-dots{
        position: relative;
        &:before{
            content: "";
            background: url("../../images/Dots.png");
            background-size: contain;
            background-repeat: no-repeat;
            position: absolute;
        }
        &.all-teams-header:before{
            width: 146px;
            height: 67px;
            right: 0px;
            top: -122px;
            @media (max-width: $max-width-sm) {
                right: 10px;
                top: -88px;
            }
        }

        &.edm-mockup-img-section:before{
            width: 197px;
            height: 90px;
            left: -110px;
            @media (min-width: $min-width-sm) and  (max-width: $max-width-md) {
                width: 100px;
                top: 17px;
                left: 50px;
            }
            @media (max-width: $max-width-sm) {
                width: 100px;
                top: -5px;
                left: 20%;
            }
        }

        &.ars-container:before{
            width: 146px;
            height: 67px;
            left: 41px;
            top: -110px;
        }
    }

    /** cookie for all pages **/
    .cookie-notification-alert{
        display: flex;
        justify-content: center;
        position: fixed;
        bottom: 0;
        width: 100%;
        background: transparent;
        padding: 0px 10px;
        z-index: 9;
    }
    .cookie-notification-alert .alert{
        border-radius: 3px;
        box-shadow: 0 2px 4px 0 rgba(85,92,106,.3);
        color: #8189a9;
        font-size: 13px;
        line-height: 14px;
        height: auto!important;
        padding: 11px!important;
        background: hsla(0,0%,100%,.9);
    }
    @media (max-width: $max-width-sm) {
        .cookie-notification-alert .alert{
            padding-right: 35px!important;
        }
    }
    @media (min-width: $min-width-md){
        .cookie-notification-alert .close{
            position: static;
            padding: 0px;
            padding: 0px 0px 0px 10px;
            margin-top: -6px;
        }
        .cookie-notification-alert .close span{
            font-size: 20px;
        }
    }

    /**
     * Overriding default bootstrap btn-primary
     */
    .btn-primary {
        background-color: $button-background-color;
        border: $button-background-color;
        border-radius: 25px;
        font-size: #{ (18 / 16) }rem;
        line-height: #{ (21 / 16) }rem;

        &:hover {
            background-color: $button-background-color-hover;
        }
    }
    .btn-small-cm{
        font-size: #{ (11 / 16) }rem;
        line-height: #{ (14 / 16) }rem;
        padding: 9px 33px;
    }
    .btn-lm{
        padding: 16px 34px;
    }
    .btn-talk{
        padding: 16px 73px;
        @media (max-width: $max-width-sm) {
            width: 100%;
        }
    }
    .btn-cta{
        padding: 16px 45px;
        @media (max-width: $max-width-sm) {
            width: 95%;
        }
    }

    .btn-blue {
        height: 32px;
        width: 130px;
        padding: 0px;
        border-radius: 5px;
        background-color: $button-background-blue-color;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        text-shadow: 0 1px 0 0 #FFFFFF;
        color: #FFFFFF;
        &:hover {
            background-color: $button-background-blue-color-hover;
            color: #FFFFFF;
        }
    }

    .btn-ghost {
        height: 32px;
        width: 130px;
        padding: 0px;
        border: 1px solid $button-background-blue-color;
        border-radius: 5px;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        text-shadow: 0 1px 0 0 #FFFFFF;
        color: $txt-blue-color;

        &:hover {
            background-color: $button-background-blue-color;
            color: #FFFFFF;
        }
    }

    .btn-ghost-green {
        background-color: transparent;
        border: 1px solid $button-background-color;
        color: $button-background-color;
        &:hover {
            background-color: $button-background-color-hover;
            border: 1px solid $button-background-color;
        }
    }

    .thankyou-page-btn {
        width: 169px;
        padding: 16px 0px;
        @media (max-width: $max-width-sm) {
            width: 125px;
            padding: 12px 0px;
        }
    }


}

.all-teams-section{
    padding: 90px 0px;    
    h2{
        margin-bottom: 65px;
    }
    .card{
        margin-top: 57px;
        border-radius: 8px;	
        background-color: #FFFFFF;	
        box-shadow: 0 2px 27px 0 rgba(129,137,169,0.33), 0 2px 27px 0 rgba(129,137,169,0.33);
        @media (max-width: $max-width-md) {
            width: 100%;
        }
        @media (max-width: $max-width-sm) { 
            width: 100%;
            margin-bottom: 40px;
            &.last-card{
                margin-bottom: 0px;
            }
        }
      
        img{
            width: 114px;
            align-self: center;
            margin-top: -57px;
        }
        @media (min-width: $min-width-md) {
            width: 209px;	
            .btn{
                display: none;
            }
            &:hover{
                cursor: pointer;
                margin-top: 37px;
                .btn{
                    display: block;
                }
                .card-text{
                    display: none;
                }
            }
        }
    }
    @media (max-width: $max-width-sm) { 
        padding: 60px 0px;  
        h2{
            margin-bottom: 55px;
        } 
    }
}

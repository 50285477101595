.customer-service-landing-page{
    .banner-section{
        background-image: url("../../images/banner-img-customer-service.jpg");
        background-repeat: no-repeat;
        @media (min-width: 1280px) {
            background-position-x: 80px;
            background-color: #645CCB;
        }

        @media (min-width: $min-width-xl) {
            min-height: 500px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        @media (max-width: $max-width-sm) {
            .banner-items{
                padding-top: 255px;
            }
        }
        .banner-items{
            .card-title{
                width: 49%;
                @media (max-width: $max-width-sm) {
                    width: 100%;
                }
            }
        }
    }
    .help-your-team{
        h2.heading-type-2{
            &+p{
                margin-bottom: 37px;
            }
        }
        h2.heading-type-4{
            width: 70%;
        }
        .hyt-img-header{
            .hyt-img-section{
                img{
                    width: 75px;
                }
            }
        }
        .help-team{
            h3{
                @media (min-width: $min-width-xl) {
                    width: 80%;
                }
            }
        }
    }
    .continer-show-dots{
        &:before{
            top: -70px;
            width: 146px;
            height: 67px;
            right: 30px;

            @media (max-width: $max-width-sm) {
                top: -67px;
                width: 120px;
            }
        }
    }
    .why-service-team{
        padding: 65px 0px 67px;
        @media (max-width: $max-width-sm) {
            padding-top: 92px;
            padding-bottom: 0px;
        }
    }
    .citation{
        margin-bottom: 20px;
        a{
            color: $font-color;
        }
    }
    .testimonial-2{
        blockquote .blockquote-body{
            width: 70%;
        }
    }

    .heading-type-1, .heading-type-2, .heading-type-3, .heading-type-4{
        @include font-family-Circular();
        font-weight: bold;
    }
    .heading-type-1{
        font-size: #{ (45 / 16) }rem;
        line-height: #{ (52 / 16) }rem;
        color: $color-white;
        width: 40%;
        @media (max-width: $max-width-sm) {
            font-size: #{ (35 / 16) }rem;
            line-height: #{ (41 / 16) }rem;
            width: 100%;
            color: $h1-color;
        }
    }
    .heading-type-2{
        font-size: #{ (30 / 16) }rem;
        line-height: #{ (25 / 16) }rem;
        @media (max-width: $max-width-sm) {
            font-size: #{ ( 25 / 16) }rem;
            line-height: #{ ( 30 / 16) }rem;
        }
    }
    .heading-type-3{
        font-size: #{ (30 / 16) }rem;
        line-height: #{ (52 / 16) }rem;
        @media (max-width: $max-width-sm) {
            font-size: #{ ( 25 / 16) }rem;
            line-height: #{ ( 30 / 16) }rem;
        }
    }
    .heading-type-4{
        font-size: #{ (30 / 16) }rem;
        line-height: #{ (40 / 16) }rem;
        color: $txt-blue-color;

    }

    .p-type-1{
        @include font-family-AkkuratPro();
        color: $color-white;
        font-size: #{ (28 / 16) }rem;
        line-height: #{ (34 / 16) }rem;
        width: 41%;
        @media (max-width: $max-width-sm) {
            width: 100%;
            font-size: #{ (21 / 16) }rem;
            line-height: #{ (28 / 16) }rem;
            color: $h1-color;
        }
    }

    blockquote{
        .blockquote-body{
            @include font-family-AkkuratPro();
            font-size: #{ (20 / 16) }rem;
            line-height: #{ (28 / 16) }rem;
            @media (max-width: $max-width-sm) {
                font-size: #{ (18 / 16) }rem;
                line-height: #{ (26 / 16) }rem;
            }
        }

        .blockquote-footer-1{
            .author{
                font-size: #{ (20 / 16) }rem;
                line-height: #{ (24 / 16) }rem;
                font-style: normal;
            }
            .position{
                font-size: #{ (12 / 16) }rem;
                line-height: #{ (15 / 16) }rem;
                font-style: normal;
            }
        }
    }
    .hr-landing-page{
        blockquote{
            .blockquote-body{
                line-height: #{ (32 / 16) }rem;
            }
        }
    }

    .help-your-team{
        .help-team{
            h3{
                font-size: #{ (20 / 16) }rem;
                line-height: #{ (24 / 16) }rem;
                font-weight: bold;
            }
        }
    }
    .why-service-team{
        .card-row{
            h3{
                font-size: #{ (20 / 16) }rem;
                line-height: #{ (24 / 16) }rem;
                font-weight: bold;
            }
        }
    }
    .footer{
        a:not(.btn){
            color: $font-color-footer;
            font-size: #{ (14 / 16) }rem;
            line-height: #{ (17 / 16) }rem;
        }
        .copy-tm{
            font-size: #{ (12 / 16) }rem;
            line-height: #{ (15 / 16) }rem;
        }
        h4{
            color: $h1-color;
        }
        .heading-type-4{
            font-size: #{ (14 / 16) }rem;
            line-height: #{ (17 / 16) }rem;
        }
    }
}

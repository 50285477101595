body {
  // font-family: 'AkkuratPro';
  @include font-family-AkkuratPro();
  color: $font-color;
}

.text-color-grey {
  color: $font-color-footer;
}
.txt-green{
  color: $button-background-color;
}
.txt-blue{
  color: $txt-blue-color;
}


// Global Typography Styles

.pre-header {
  font-size: #{ (14 / 16) }rem;
  line-height: #{ (17 / 16) }rem;
  letter-spacing: 3px;
  @media (max-width: $max-width-sm) {
    font-size: #{ (12 / 16) }rem;
    line-height: #{ (15 / 16) }rem;
    letter-spacing: 2px;
  }
}

h1 {
  @include font-family-Circular-Std-Bold();
  font-size: #{ (45 / 16) }rem;
  line-height: #{ (52 / 16) }rem;
  @media (max-width: $max-width-sm) {
    font-size: #{ (26 / 16) }rem;
    line-height: #{ (41 / 16) }rem;
  }
}

h2 {
  @include font-family-Circular-Std-Bold();
  font-size: #{ (30 / 16) }rem;
  line-height: #{ (40 / 16) }rem;
  @media (max-width: $max-width-sm) {
    font-size: #{ (22 / 16) }rem;
    line-height: #{ (30 / 16) }rem;
  }
}

h3 {
  @include font-family-Circular-Std-Bold();
  font-size: #{ (20 / 16) }rem;
  line-height: #{ (30 / 16) }rem;
  @media (max-width: $max-width-sm) {
    font-size: #{ (18 / 16) }rem;
    line-height: #{ (26 / 16) }rem;
  }
}

h4 {
  font-size: #{ (24 / 16) }rem;
  line-height: #{ (29 / 16) }rem;
  @media (max-width: $max-width-sm) {
    font-size: #{ (18 / 16) }rem;
    line-height: #{ (21 / 16) }rem;
  }
}

h5 {
  font-size: #{ (14 / 16) }rem;
  line-height: #{ (17 / 16) }rem;
  @media (max-width: $max-width-sm) {
    font-size: #{ (12 / 16) }rem;
    line-height: #{ (15 / 16) }rem;
  }
}

h6 {
  font-size: #{ (14 / 16) }rem;
  line-height: #{ (17 / 16) }rem;
  @media (max-width: $max-width-sm) {
    font-size: #{ (12 / 16) }rem;
    line-height: #{ (15 / 16) }rem;
  }
}

p {
  font-size: #{ (16 / 16) }rem;
  line-height: #{ (30 / 16) }rem;
  @media (max-width: $max-width-sm) {
    font-size: #{ (15 / 16) }rem;
    line-height: #{ (26 / 16) }rem;
  }
}

// Custom typography Styles

.heading-type-1, .heading-type-2, .heading-type-2-white{
  @include font-family-Circular-Std-Bold();
  font-weight: bold;
}
.heading-type-3, .heading-type-4, .btn-small-cm{
  @include font-family-AkkuratPro();
  font-weight: bold;
}
.heading-type-1{
  font-size: #{ (45 / 16) }rem;
  line-height: #{ (52 / 16) }rem;
  color: $h1-color;
  @media (max-width: $max-width-sm) {
    font-size: #{ (35 / 16) }rem;
    line-height: #{ (41 / 16) }rem;
    width: 100%;
  }

  @media (max-width: 320px) {
    font-size: #{ (30 / 16) }rem;
    line-height: #{ (35 / 16) }rem;
  }
}
.heading-type-2{
  font-size: #{ (30 / 16) }rem;
  line-height: #{ (52 / 16) }rem;
  color: $h1-color;
  @media (max-width: $max-width-sm) {
    font-size: #{ ( 25 / 16) }rem;
    line-height: #{ ( 30 / 16) }rem;
  }
}
.heading-type-2-white{
  color: $color-white;
  font-size: #{ (35 / 16) }rem;
  line-height: #{ (44 / 16) }rem;
  @media (max-width: $max-width-sm) {
    font-size: #{ (30 / 16) }rem;
    line-height: #{ (38 / 16) }rem;
  }
}
.heading-type-3{
  font-size: #{ (20 / 16) }rem;
  line-height: #{ (24 / 16) }rem;
  @media (max-width: $max-width-sm) {
    font-size: #{ ( 19 / 16) }rem;
    line-height: #{ ( 23 / 16) }rem;
  }
}
.heading-type-4{
  font-size: #{ (14 / 16) }rem;
  line-height: #{ (17 / 16) }rem;
}
.navigation-section{
  a{
    @include font-family-AkkuratPro();
    color: rgba(0,0,0,.9);
    font-weight: bold;
    font-size: #{ (11 / 16) }rem;
    line-height: #{ (14 / 16) }rem;

    @media (max-width: $max-width-sm) {
      &.nav-link{
        @include font-family-Circular-Std-Bold();
        font-weight: bold;
        font-size: #{ (16 / 16) }rem;
        line-height: #{ (19 / 16) }rem;
      }
    }
  }
  .dropdown-item{
    @include font-family-AkkuratPro();
    color: $font-color-footer;
    font-weight: 400;
    font-size: #{ (14 / 16) }rem;
    line-height: #{ (30 / 16) }rem;
  }
}

.p-type-1{
  @include font-family-AkkuratPro();
  color: $h1-color;
  font-size: #{ (28 / 16) }rem;
  line-height: #{ (34 / 16) }rem;
  @media (max-width: $max-width-sm) {
    width: 100%;
    font-size: #{ (21 / 16) }rem;
    line-height: #{ (28 / 16) }rem;
  }
}

.p-type-2{
  @include font-family-AkkuratPro();
  font-weight: bold;
  font-size: #{ (20 / 16) }rem;
  line-height: #{ (24 / 16) }rem;
  color: $txt-blue-color;
}

blockquote{
  .blockquote-body{
    @include font-family-AkkuratPro();
    font-size: #{ (20 / 16) }rem;
    line-height: #{ (28 / 16) }rem;
    @media (max-width: $max-width-sm) {
      font-size: #{ (18 / 16) }rem;
      line-height: #{ (26 / 16) }rem;
    }
  }

  .blockquote-footer-1{
    .author{
      font-size: #{ (20 / 16) }rem;
      line-height: #{ (24 / 16) }rem;
      font-style: normal;
    }
    .position{
      font-size: #{ (12 / 16) }rem;
      line-height: #{ (15 / 16) }rem;
      font-style: normal;
    }
  }
}

.footer{
  a:not(.btn){
    color: $font-color-footer;
    font-size: #{ (14 / 16) }rem;
    line-height: #{ (17 / 16) }rem;
  }
  .copy-tm{
    font-size: #{ (12 / 16) }rem;
    line-height: #{ (15 / 16) }rem;
  }
  h4{
    color: $h1-color;
  }
}

.vox-style-guide-page{
  h4 {
    @include font-family-Circular-Std-Bold();
    font-size: #{ (30 / 16) }rem;
    line-height: #{ (40 / 16) }rem;
    letter-spacing: -0.003em;
  }

  .join-customer-section{
    h6{
      font-size: #{ (15 / 16) }rem;
      line-height: #{ (20 / 16) }rem;
      font-size: 15px;
      line-height: 20px;
      color: #8189A9;
      mix-blend-mode: normal;
      opacity: 0.7;
    }
  }

  .sponsor-section-container {
    h3.service-title{
      @include font-family-AkkuratPro();
      font-weight: bold;
      font-size: #{ (21 / 16) }rem;
      line-height: #{ (32 / 16) }rem;
    }
  }

  .purple-container{
    h2{
      @include font-family-Circular-Std-Bold();
      font-size: #{ (40 / 16) }rem;
      line-height: #{ (58 / 16) }rem;
      letter-spacing: -0.2px;
      color: #FFF;
    }

    p{
      font-size: #{ (21 / 16) }rem;
      line-height: #{ (36 / 16) }rem;  
    }
  } 

  @media (max-width: $max-width-sm) {
    h4 {
      font-size: #{ (24 / 16) }rem;
      line-height: #{ (32 / 16) }rem;
      letter-spacing: -0.0025em;
    }

    .purple-container{
      h2{
        font-size: #{ (30 / 16) }rem;
        line-height: #{ (40 / 16) }rem;
      }
  
      p{
        font-size: #{ (18 / 16) }rem;
        line-height: #{ (28 / 16) }rem;  
      }
    } 

  }
  

}

.ml-gm-page{
  .align-team-with-company-section{
    a{
      font-weight: bold;
      font-size: 16px;
      line-height: 32px;
      color: #4C7AF1;
    }
  }
}

/** option sidebar custom **/
.option-sidebar-container{
  h3{
    font-size: #{ (24 / 16) }rem;
    line-height: #{ (30 / 16) }rem;   
    color: $h1-color; 
  }
  h4{
    font-size: #{ (20 / 16) }rem;
    line-height: #{ (24 / 16) }rem; 
    font-weight: bold;
  }
  .txt-reset{
    font-size: #{ (12 / 16) }rem;
    line-height: #{ (15 / 16) }rem;  
    text-transform: uppercase;
    font-weight: bold;
    &:hover{
      color: $button-background-color;
      text-decoration: none;
    }
    @media (max-width: $max-width-sm) {
      font-size: #{ (14 / 16) }rem;
      line-height: #{ (17 / 16) }rem; 
    }
  }
  .form-check{
    label{
      color: $font-color;
      font-size: #{ (16 / 16) }rem;
      line-height: #{ (26 / 16) }rem;
    }
  }
}
.mobile-sticky-sort-option-container{
  a {
    &.click-link-custom{
      @include font-family-Circular-Std-Bold();
    }
    font-size: #{ (20 / 16) }rem;
    line-height: #{ (25 / 16) }rem;
    color: $h1-color;

    &:hover{
      text-decoration: none;
    }
  }
}

/** content box custom css **/
.content-box-custom{
  .title{
    font-size: #{ (20 / 16) }rem;
    line-height: #{ (25 / 16) }rem;   
    color: $h1-color;
  }
  .content{
    font-size: #{ (16 / 16) }rem;
    line-height: #{ (25 / 16) }rem;   
    color: $font-color;
  }
  .author{
    font-size: #{ (14 / 16) }rem;
    line-height: #{ (17 / 16) }rem;   
    color: $font-color;
    font-weight: bold; 
  }
  .publish-info{
    font-size: #{ (14 / 16) }rem;
    line-height: #{ (17 / 16) }rem;   
    color: $font-color-footer; 
  }
  .content-info{
    font-size: #{ (12 / 16) }rem;
    line-height: #{ (15 / 16) }rem;   
    color: $font-color-footer; 
    color: $txt-blue-color;
    font-weight: bold;
  }
  .cta-link{
    font-size: #{ (14 / 16) }rem;
    line-height: #{ (17 / 16) }rem; 
    color: $button-background-color;
    font-weight: bold;
  }
}

/** blog resources **/
.blog-resources-container{
  .sort-section{
    .btn.dropdown-toggle{
      font-size: #{ (11 / 16) }rem;
      line-height: #{ (14 / 16) }rem; 
      font-weight: bold;
      letter-spacing: 0.9px;
      color: $font-color;
      text-transform: uppercase;
    }
    p{
      font-size: #{ (14 / 16) }rem;
      line-height: #{ (17 / 16) }rem; 
      color: $txt-blue-color;
    } 
  }
}

/** page container **/
.pagination-container{
  .page-link{
    font-size: #{ (18 / 16) }rem;
    line-height: #{ (22 / 16) }rem;  
  }
}

.container-type-2-custom{
  .txt-section{
    h3{
      font-size: #{ (18 / 16) }rem;
      line-height: #{ (32 / 16) }rem;   
    }
  }
}

.blind-case-study-page{
  .QuoteContainer{
    h3{
      font-size: #{ (29 / 16) }rem;
      line-height: #{ (38 / 16) }rem; 
      font-weight: 700; 
    }  
  } 
}

.frost-and-sullivan-landing-page {
  .heading-type-2-44p{
    font-size: #{ (44 / 16) }rem;
    line-height: #{ (52 / 16) }rem;   
  }
  .heading-type-2-32p{
    font-size: #{ (32 / 16) }rem;
    line-height: #{ (40 / 16) }rem;   
  }
  .h3-type-1{
    font-size: #{ (24 / 16) }rem;
    line-height: #{ (32 / 16) }rem;   
  }
  .para-type-1{
    font-size: #{ (18/ 16) }rem;
    line-height: #{ (32 / 16) }rem;   
  }
  .para-type-2{
    font-size: #{ (16/ 16) }rem;
    line-height: #{ (28 / 16) }rem;   
  }
  .para-type-3{
    font-size: #{ (20/ 16) }rem;
    line-height: #{ (32 / 16) }rem;   
  }

  .anchor-type-1{
    font-size: #{ (14/ 16) }rem;
    line-height: #{ (32/ 16) }rem; 
    color: $txt-blue-color2;
    font-weight: bold;  
  }

  .headerContainer{
    h1{
      font-size: #{ (58 / 16) }rem;
      line-height: #{ (64 / 16) }rem; 
    }  
    p{
      font-size: #{ (20 / 16) }rem;
      line-height: #{ (32 / 16) }rem;  
    } 
    .btn{
      font-size: #{ (14 / 16) }rem;
      line-height: #{ (16 / 16) }rem;  
      font-weight: bold;  
    } 
    @media (max-width: $max-width-sm){
      h1{
        font-size: #{ (38 / 16) }rem;
        line-height: #{ (44 / 16) }rem; 
        text-align: center;
      }  
      p{
        font-size: #{ (19 / 16) }rem;
        line-height: #{ (32 / 16) }rem;  
        text-align: center;
      } 
    }
  }

  .form-section{
    @media (max-width: $max-width-sm){
      h2{
        font-size: #{ (24/ 16) }rem;
        line-height: #{ (32 / 16) }rem;  
        text-align: center; 
      }
    }
  }
  
  .hacking-book-section{
    @media (max-width: $max-width-sm){
      h2{
        font-size: #{ (24/ 16) }rem;
        line-height: #{ (32 / 16) }rem;  
      }
    }
  }

  .related-resources-section{
    @media (max-width: $max-width-sm){
      h2{
        font-size: #{ (24/ 16) }rem;
        line-height: #{ (32 / 16) }rem;  
        text-align: center; 
      }
      h3.heading-type-3{
        font-size: #{ (18/ 16) }rem;
        line-height: #{ (28 / 16) }rem;  
      }
      a{
        font-size: #{ (16/ 16) }rem;
        line-height: #{ (32 / 16) }rem;    
      }
    }  
  }
  

  .rrs-block-tile{
    p{
      font-size: #{ (18 / 16) }rem;
      line-height: #{ (32 / 16) }rem;    
    }
    .resource-title{
      font-size: #{ (11 / 16) }rem;
      line-height: #{ (16 / 16) }rem;  
      color: $txt-light-grey-1;
      text-transform: uppercase;
    }
    .heading-type-3{
      font-size: #{ (24 / 16) }rem;
      line-height: #{ (32 / 16) }rem;  
    }
    a{
      font-size: #{ (14 / 16) }rem;
      line-height: #{ (32 / 16) }rem;  
      font-weight: bold;
      color: $txt-blue-color2;
    }
  }
}

.footer{
    .footer-gb,.footer-resource{
        a{
            display: block;
            margin-bottom: 5px;
        }
    }
    .footer-logo{
        img{
            display: block;
            margin-bottom: 40px;
        }
    }
    .copy-tm{
        display: block;
        margin-top: 40px;
    }
    h4{
        margin-bottom: 14px;
    }
}
.cpanel-case-study-page {

    .headerContainer {
        .banner-hero-image{
            @media (min-width: $min-width-xl){
                max-width: unset;
                width: auto;
                height: 330px;
            }
        }
    }
    .container-type-2-custom .txt-section .txt-copy{
        justify-content: flex-start;
    }
}


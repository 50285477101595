.accelarate-results-section{
    padding: 80px 0px;
    h2{
        margin-bottom: 60px;
    }
    video{
        max-width: 930px;
        width: 100%;
        margin-bottom: 60px;
    }
}
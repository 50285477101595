.beyond-grammar-section{
    padding: 90px 0px;
    //overflow: hidden;
    h2{
        margin-bottom: 65px;
    }
    .bgs-gb{
        margin-bottom: 20px;
        img{
            width: 612px;
            margin-left: -136px;
            @media (max-width: $max-width-xl) {
                margin-left: 0px;
            }
        }
        .bgs-text-section{
            padding-top: 60px;
        }
    }
    .bgs-pa{
        margin-bottom: 50px;
        .bgs-text-section{
            padding-top: 80px;
        }
        img{
            width: 463px;
            position: relative;

        }
    }
    .bgs-centralised{
        margin-bottom: 80px;
        .bgs-text-section{
            padding-top: 45px;
        }
        img{
            width: 527px;
            // margin-left: -75px;
            @media (max-width: $max-width-xl) {
                margin-left: 0px;
            }
        }
    }
    .bgs-wi{
        .bgs-text-section{
            padding-top: 45px;
        }
        img{
            width: 530px;
        }
    }
    .bgs-text-section{
        h3{
            margin-bottom: 20px;
        }
        p{
            margin-bottom: 40px;
        }
    }
    @media (max-width: $max-width-xl) {
        .bgs-text-img-section{
            img{
                width: 100%;
            }
        }
    }
    @media (min-width: $min-width-sm) and  (max-width: $max-width-md) {
        .bgs-gb, .bgs-pa, .bgs-centralised, .bgs-wi{
            margin-bottom: 90px;
            .bgs-text-section{
                padding-top: 30px;
            }
        }
        .bgs-text-img-section{
            img{
                padding-top: 30px;
            }
        }
    }
    @media (max-width: $max-width-sm) {
        padding: 60px 0px;
        h2{
            margin-bottom: 45px;
        }
        .bgs-gb, .bgs-pa, .bgs-centralised, .bgs-wi{
            margin-bottom: 60px;
            .bgs-text-section{
                p{
                    margin-bottom: 0px;
                }
                padding-top: 40px;
            }
        }

    }

}

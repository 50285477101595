/** testimonial: independent testimonial **/
.testimonial-2{
    padding: 45px 0px 54px; 
    blockquote{
      .blockquote-body{
        width: 58%;
        @media (max-width: $max-width-sm) { 
          width: 100%;  
        }
        margin-left: auto;
        margin-right: auto;
      }
    }
}
.get-in-touch-1-page{
    .header-section {
        background-image: url("../../images/banner-img-customer-service.jpg");
        background-size: contain;
        background-position: top right;
        background-repeat: no-repeat;
        background-color: #645DCB;
        
        .inner-section-col{
            padding-bottom: 120px;
        }
        
        @media (min-width: $min-width-md) and (max-width: 1570px) {
            background-size: 90%;
            background-position: bottom right;
        }

        @media (max-width: $max-width-md) {
            .inner-section-col{
                padding-bottom: 0px;
            }
            background-color: #FFF;
            
            background-repeat: no-repeat;
            background-position: top right;
            padding-top: 340px;

            h1{
                color: $h1-color;
            }
            p{
                color: $h1-color;  
            }
            .btn{
                margin-bottom: 20px;
            }
        }

        @media (max-width: 810px) {
            padding-top: 280px;
        }

        @media (max-width: $max-width-sm) {
            background-size: 150% auto;
            .btn{
              margin-bottom: 0px;
            }
        }
        
    }
}
blockquote{
    .inverted-comma{
      margin: 15px 0px;
      width: 31px;
    }
    .blockquote-body{
      color: $font-color-footer;
      margin-bottom: 29px;
      font-style: italic;
    }

    .blockquote-footer-1{
        .author{
            display: block;
            color: $button-background-color;
            font-weight: bold;
        }
        .position{
            display: block;
            color: $font-color-footer;
            text-transform: uppercase;
            letter-spacing: 2px;
        }
    }
}
.continer-show-dots{
  position: relative;
  &:before{
    content: "";
    background: url("./../images/Dots.png");
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
  }
  &.all-teams-header:before{
    width: 146px;
    height: 67px;
    right: 0px;
    top: -122px;
    @media (max-width: $max-width-sm) {
      right: 10px;
      top: -88px;
    }
  }

  &.edm-mockup-img-section:before{
    width: 197px;
    height: 90px;
    left: -110px;
    @media (min-width: $min-width-sm) and  (max-width: $max-width-md) {
      width: 100px;
      top: 17px;
      left: 50px;
    }
    @media (max-width: $max-width-sm) {
      width: 100px;
      top: -5px;
      left: 20%;
    }
  }

  &.ars-container:before{
    width: 146px;
    height: 67px;
    left: 41px;
    top: -110px;
  }
}

/** cookie for all pages **/
.cookie-notification-alert{
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: transparent;
  padding: 0px 10px;
  z-index: 999;
}
.cookie-notification-alert .alert{
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 rgba(85,92,106,.3);
  color: #8189a9;
  font-size: 13px;
  line-height: 14px;
  height: auto!important;
  padding: 11px!important;
  background: hsla(0,0%,100%,.9);
}
@media (max-width: $max-width-sm) {
  .cookie-notification-alert {
    .alert{
      padding-right: 35px!important;
    }
    .close {
      position: absolute;
      right: 10px;
      padding: 15px;
    }
  }
}
@media (min-width: $min-width-md){
  .cookie-notification-alert .close{
    position: static;
    padding: 0px;
    padding: 0px 0px 0px 10px;
    margin-top: -15px;
  }
  .cookie-notification-alert .close span{
    font-size: 20px;
  }
}

/** green highlights for different screens **/
.gh-d-mob{
  display: inline;
  @media (min-width: $min-width-sm) {
      display: none;
  }
}
.gh-d-ipad{
  display: none;
  @media (min-width: $min-width-sm) and (max-width: 959px) {
      display: inline;
  }    
}
.gh-d-laptop{
  display: none;
  @media (min-width: 960px) {
      display: inline;
  }
}

.gh-d-lg-ipad{
  display: none;
  @media (min-width: 960px) and (max-width: $max-width-xl) {
      display: inline;
  }    
}

.gh-d-large-screen{
  
  display: none;
  @media (min-width: 1200px) {
      display: inline;
  }
}
/** green highlights for different screens **/


/** style select dropdown input **/
.rs-dropdown-container-custom{
  text-align: left;
  color: #000000;
  margin-top: 15px;
  cursor: pointer;
  input{
    margin-top: 0px!important;
    height: 33px!important;
  }
  .caret-down {
    display: inline-block;
    width: 0;
    height: 0;
    margin-left: 2px;
    vertical-align: middle;
    border-top: 8px solid #8189A9;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }

  /*
  > div:first-child > div:nth-child(2){
    opacity: 0;
    height: 35px;
  }
  > div:nth-child(2) > div:nth-child(2){
    opacity: 0;
    height: 35px;
  }
  > div:first-child,
  > div:nth-child(2){
    cursor: pointer;
    // cursor: text;
  }
  position: relative;
  &:before{
    content: "";
    position: absolute;
    width: 0px;
    height: 0px;
    // z-index: 999;
    border-top: 10px dashed #8189A9;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    right: 10px;
    top: 40%;
  }
  */
} 

.click-link-custom{
  cursor: pointer;
}
.email-validation-label+br{
  display: none;
}
.email-validation-label.d-none+br{
  display: block;
}

.z-index-99{
  z-index: 99;
}
@media (min-width: $min-width-xl){
  .mw-xl-75-custom{
    max-width: 75%;
  }
  .pr-xl-15p-custom{
    padding-right: 15px;
  }
  .mw-xl-67-custom{
    max-width: 67%;
  }
  .mw-xl-63-custom{
    max-width: 63%;
  }
  .mw-md-270p-custom{
    max-width: 270px;
  }
}



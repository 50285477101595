.banner-section{
    background: url("../../images/banner-img.png");
    background-size: cover;
    @media (min-width: $min-width-sm) and (max-width: $min-width-md) {
      background-position: bottom;
    }
    @media (max-width: $max-width-md) {
      // background-position: center;
    }
    @media (max-width: $max-width-sm) {
      background-size: 150% auto;
      background-repeat: no-repeat;
      background-position: top right;
    }
    .banner-items{
      padding: 52px 0px 67px;
      @media (max-width: $max-width-sm) {
        padding: 230px 0px 38px;
      }
    }
    .banner-logo{
      margin-bottom: 55px;

      @media (max-width: $max-width-sm) {
        margin-bottom: 300px;
      }
      @media (max-width: $max-width-xs) {
        margin-bottom: 230px;
      }
    }
    .card-title{
      margin-bottom: 25px;
      @media (max-width: $max-width-sm) {
        margin-bottom: 20px;
      }
    }
    .card-text{
      margin-bottom: 44px;
      @media (max-width: $max-width-sm) {
        margin-bottom: 35px;
      }
    }
}

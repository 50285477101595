/** help your team section **/
.help-your-team{
    padding: 64px 0px 64px;
    .hyt-img-section{
      img{
        margin-bottom: 37px;
      }
      h2{
        width: 60%;
        margin: auto;
        margin-bottom: 72px;
        @media (max-width: $max-width-sm) {
          width: 100%;
          margin-bottom: 40px;
        }
      }
    }
    .hr-line{
        height: 1px;
        width: 100%;
        border: 1px solid rgba(129,137,169,0.5);
        @media (max-width: $max-width-xs) {
          margin-left: 15px;
          margin-right: 15px;
        }
    }
    .help-team{
      @media (max-width: $max-width-sm) {
        &>div{
          margin-bottom: 36px;
          &:last-child{
            margin-bottom: 0px;
          }
        }
      }  
      h2{
        margin: 63px 0px 37px;
        color: $h1-color;
        @media (max-width: $max-width-sm) {
          margin-top: 40px;
          margin-bottom: 45px;
        }
      }
      h3{
          margin-bottom: 8px; 
      }
      margin-bottom: 33px;
      @media (max-width: $max-width-sm) {
        margin-bottom: 0px;
        margin-top: 0px;

      }
    }
}
.design-2{
  .help-your-team{
    padding: 65px 0px 65px;
    .hyt-sub-header{
      h2{
        margin-top: 0px;
      }
    }
  }
}

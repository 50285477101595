.new-home-landing-page {
  h1 {
    color: #1c1e29;
  }

  h2 {
    color: #1C1E29;
  }

  h3 {
    color: #1c1e29;
  }

  h4 {
    color: #1c1e29;
  }

  h5 {
    color: #3b3e4d;
  }

  // h6 {
  //   font-size: 15px;
  //   line-height: 20px;
  //   color: #8189a9;
  // }

  // p {
  //   font-size: 21px;
  //   letter-spacing: 0;
  //   line-height: 36px;
  //   color: #3b3e4d;
  // }

  .pre-header {
    color: #8189a9;
  }

  ul {
    padding-left: 0px;
  }

  li {
    margin: 0;
    padding: 0px 0px 0px 25px;
    list-style: none;
    background-image: url("./../../../../assets/images/newHome/tick.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 15px;
  }

  .green-text {
    color: #17d6a9;
  }

  .green-btn,
  .form-container input[type="submit"],
  .form-container input[type="button"] {
    padding: 14px 40px;
    height: 48px;
    border-radius: 25px;
    background-color: #15c39a;
    border: none;
    color: #ffffff;
    text-decoration: none;
  }

  .hiden-btn {
    visibility: hidden;
  }

  .header-section {
    background: linear-gradient(225deg, #736be8 0%, #4c7af1 100%);
    padding-bottom: 50px;
  }

  .header-section h1,
  .header-section p {
    color: #ffffff;
    margin-top: 0px;
  }

  .grey-container {
    background: #f9fafc;
  }

  .coming-soon-container {
    background: #9ba6c4;
    font-size: 11px;
    line-height: 16px;
    font-family: "AkkuratPro", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffffff;
    width: fit-content;
    padding: 2px 10px 0px 10px;
    border-radius: 20px;
  }

  .administration-tools-container {
    background: #4a6ee0;
    font-size: 11px;
    line-height: 16px;
    font-family: "AkkuratPro", sans-serif;
    font-weight: bold;
    text-transform: uppercase;
    color: #ffffff;
    width: fit-content;
    padding: 2px 10px 0px 10px;
    border-radius: 20px;
  }

  /* Header Styles */

  .menu-container {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.9px;
    color: #3b3e4d;
    text-align: right;
  }

  .menu-container > span {
    margin: 30px 20px;
    display: inline-block;
  }

  .form-container {
    height: fit-content;
    width: 100%;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 27px 0 rgba(129, 137, 169, 0.33);
    padding-top: 20px;
    z-index: 9;
    @media (max-width: $max-width-sm) {
      margin-top: 30px;
    }
  }

  .header-lines {
    right: -100px;
    top: 70px;
    z-index: 0;
  }

  .header-lines img {
    max-width: 200px;
  }

  .header-dots {
    height: 45px;
    margin-bottom: -45px;
    @media (max-width: $max-width-sm) {
      margin-top: 20px;
    }
  }

  .header-dots img {
    max-width: 150px;
    margin: 16px 0px 0px 20px;
  }

  .form-container .form-elements,
  .form-container .selectedBtn {
    display: none;
  }

  .form-container label {
    width: 100%;
    margin-top: 38px;
  }

  .form-container .btn-talk {
    width: auto !important;
  }

  .form-container p {
    color: #3b3e4d;
  }

  .form-container input,
  .form-container select,
  .form-container textarea {
    box-sizing: border-box;
    border: 1px solid rgba(129, 137, 169, 0.4);
    border-radius: 5px;
    width: 100%;
    height: 45px;
    margin-top: 15px;
    padding: 0px 0px 0px 10px;
  }

  .form-container input[type="submit"],
  .form-container input[type="button"] {
    width: auto;
    margin: 25px 0px 0px 0px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 0px 80px;
  }

  .Form-Intro-Container p {
    color: #3b3e4d;
    font-family: "AkkuratPro";
    font-size: 16px;
    line-height: 25px;
  }

  .customRadioBtn {
    display: none;
  }

  .customRadioBtn ~ label {
    padding: 10px 0px;
    border-radius: 5px;
    background-color: transparent;
    max-width: 90px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #8189a9;
    display: inline-block;
    cursor: pointer;
    margin-right: 0px;
    margin-top: 0px !important;
    margin-bottom: 0px;
    box-sizing: border-box;
    border: 1px solid rgba(129, 137, 169, 0.4);

    @media (max-width: $max-width-sm) {
      margin-right: 30px;
      margin-top: 20px !important;
    }
  }

  .customRadioBtn:checked ~ label {
    background-color: #4c7af1;
    color: #ffffff;
    display: inline-block;
  }

  .form-container textarea {
    height: 150px !important;
    padding-top: 10px;
  }

  .Thankyou-Container img {
    height: 46px;
  }
  .Thankyou-Container-1{
    padding-left: 10px;
    padding-right: 10px;
  }

  /* Logo/Sponsor Section */
  .sponsor-section-container {
    padding-top: 90px;
    padding-bottom: 100px;
    @media (max-width: $max-width-sm) {
      padding-top: 40px;
    }
  }

  .sponsor-section-title {
    font-size: 15px;
    line-height: 20px;
    color: #8189A9;
  }

  .sponsor-lable {
    min-width: 260px;
  }

  .sponsor-lable h6 {
    line-height: 12px;
  }

  .sponsor-logos {
    height: 18px;
    text-align: center;
  }

  /* Get Better Section */

  .service-title {
    font-weight: bold;
  }

  .service-text{
    font-size: 18px;
    line-height: 32px;
  }

  .service-sub-title {
    font-size: 13px;
    line-height: 16px;
    color: #a6adc9;
  }

  .image-text-block {
    height: 100px;
    margin-bottom: 20px;
  }

  .services-sponsor-images {
    height: 42px;
    padding: 8px 0px 13px 0px;
  }

  .services-sponsor-image-text {
    font-size: 9px;
    line-height: 11.52px;
    color: #a6adc9;
  }

  .section-icon {
    width: 63px;
  }

  .bottom-lines-left {
    height: 17px;
  }

  .bottom-lines-right {
    height: 34px;
  }

  .bottom-dots-right {
    height: 133px;
  }

  // Mother Homepage Styles

  @media (max-width: $max-width-xl) {
    .gbs-image-section {
      width: 100% !important;
      height: auto !important;
      margin-left: 0px !important;
      .main-background {
        width: 100% !important;
      }
      .main-sheet2 {
        top: 21.2% !important;
        right: 5% !important;
        width: 19.2% !important;
      }
      .main-sheet1 {
        top: 35% !important;
        right: 10% !important;
        width: 20% !important;
      }
      .main-sheet {
        top: 17.9% !important;
        width: 35.9% !important;
        left: 32.7% !important;
      }
    }
    .edmAnimationSection {
      width: 100% !important;
      height: auto !important;
      .edm-img1 {
        width: 41.5% !important;
      }
      .edm-img2 {
        width: 40% !important;
        bottom: 7.6% !important;
        left: 27.5% !important;
      }
      .edm-img3 {
        width: 40% !important;
      }
    }

    #centralisedAnimation {
      width: 100% !important;
      height: auto !important;
      margin-left: 0px !important;
      #centralised-img1 {
        width: 90% !important;
      }
      #centralised-img2 {
        width: 67% !important;
      }
    }

    #lastAnimation {
      height: auto !important;
      width: 100% !important;
      .oval {
        width: 71.9% !important;
      }
      .msg-sheets1 {
        width: 58% !important;
      }
      .msg-sheets2 {
        width: 58% !important;
        top: 8.4% !important;
        left: 9.1% !important;
      }
      .msg-sheets3 {
        width: 58% !important;
        top: 17.4% !important;
        left: 18.9% !important;
      }
      .msg-sheets4 {
        width: 58% !important;
        top: 26% !important;
        left: 29% !important;
      }
    }

    @media (max-width: $max-width-md) {
      .edmAnimationSection {
        width: 100% !important;
        height: auto !important;
        .edm-img1 {
          width: 41.5% !important;
          left: 6%;
        }
        .edm-img2 {
          width: 40% !important;
          bottom: 7.6% !important;
          left: 27.5% !important;
        }
        .edm-img3 {
          width: 40% !important;
        }
      }

      .continer-show-dots.ars-container:before {
        top: -20px;
      }

      .carousel-indicators {
        bottom: 0px;
      }
      .carousel-indicators li {
        width: 9px;
        height: 9px;
        background: #ffffff;
        border: none;
        border-radius: 5px;
        border: 1px solid #8189a9;
      }
      .carousel-indicators .active {
        width: 9px;
        height: 9px;
        background: #8189a9;
      }
      .carousel-control-next,
      .carousel-control-prev {
        color: #000;
      }

      .carousel-control-prev {
        left: 0;
        background: #15c39a;
        height: 54px;
        top: 50%;
        // left: 8px;
        border-radius: 0px 40px 40px 0px;
        opacity: 1;
        width: 35px;

        .carousel-control-prev-icon {
          background-image: url("../../images/Chevron.svg");
          transform: rotate(180deg);
        }
      }

      .carousel-control-next {
        background: #15c39a;
        height: 54px;
        top: 50%;
        // right: 5px;
        border-radius: 40px 0px 0px 40px;
        opacity: 1;
        width: 35px;

        .carousel-control-next-icon {
          background-image: url("../../images/Chevron.svg");
        }
      }
    }
  }
  @media (max-width: $max-width-md) {
    .testimonial-1 {
      padding-top: 0px;
      margin-top: -70px;
    }
  }

  .banner-Btn {
    position: relative;
    z-index: 999;
  }
}

.frost-sullivan-landing-page{
    
    .QuoteContainer {
        @media (min-width: 1600px){
            background-position: 0% 40%;
        }
        @media (min-width: 2000px){
            background-position: 0% 30%;
        }
        @media (min-width: $min-width-sm) and (max-width: $max-width-xl) {
            background-position: 90% 0%;
        }
    }
}
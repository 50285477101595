.marketing-landing-page{
    .banner-section{
        background-image: url("../../images/banner-img-marketing.png");
    }
    .help-your-team{
        .hyt-img-header{
            .hyt-img-section{
                img{
                    width: 114px;
                }
            }
        }
    }
    .continer-show-dots{
        &:before{
            top: -95px;
        }
    }
    .why-service-team{
        padding: 65px 0px 67px;
        @media (max-width: $max-width-sm) {
            padding-top: 92px;
            padding-bottom: 0px;
        }
    }

    .heading-type-1, .heading-type-2, .heading-type-3, .heading-type-4{
        @include font-family-Circular();
        font-weight: bold;
    }
    .heading-type-1{
        font-size: #{ (45 / 16) }rem;
        line-height: #{ (52 / 16) }rem;
        color: $h1-color;
        width: 40%;
        @media (max-width: $max-width-sm) {
            font-size: #{ (35 / 16) }rem;
            line-height: #{ (41 / 16) }rem;
            width: 100%;
        }
    }
    .heading-type-2{
        font-size: #{ (30 / 16) }rem;
        line-height: #{ (25 / 16) }rem;
        @media (max-width: $max-width-sm) {
            font-size: #{ ( 25 / 16) }rem;
            line-height: #{ ( 30 / 16) }rem;
        }
    }
    .heading-type-3{
        font-size: #{ (30 / 16) }rem;
        line-height: #{ (52 / 16) }rem;
        @media (max-width: $max-width-sm) {
            font-size: #{ ( 25 / 16) }rem;
            line-height: #{ ( 30 / 16) }rem;
        }
    }
    .heading-type-4{
        font-size: #{ (30 / 16) }rem;
        line-height: #{ (40 / 16) }rem;
        color: $txt-blue-color;

    }

    .p-type-1{
        @include font-family-AkkuratPro();
        color: $h1-color;
        font-size: #{ (28 / 16) }rem;
        line-height: #{ (34 / 16) }rem;
        width: 40%;
        @media (max-width: $max-width-sm) {
            width: 100%;
            font-size: #{ (21 / 16) }rem;
            line-height: #{ (28 / 16) }rem;
        }
    }

    blockquote{
        .blockquote-body{
            @include font-family-AkkuratPro();
            font-size: #{ (20 / 16) }rem;
            line-height: #{ (28 / 16) }rem;
            @media (max-width: $max-width-sm) {
                font-size: #{ (18 / 16) }rem;
                line-height: #{ (26 / 16) }rem;
            }
        }

        .blockquote-footer-1{
            .author{
                font-size: #{ (20 / 16) }rem;
                line-height: #{ (24 / 16) }rem;
                font-style: normal;
            }
            .position{
                font-size: #{ (12 / 16) }rem;
                line-height: #{ (15 / 16) }rem;
                font-style: normal;
            }
        }
    }
    .hr-landing-page{
        blockquote{
            .blockquote-body{
                line-height: #{ (32 / 16) }rem;
            }
        }
    }

    .help-your-team{
        .help-team{
            h3{
                font-size: #{ (20 / 16) }rem;
                line-height: #{ (24 / 16) }rem;
                font-weight: bold;
            }
        }
    }
    .why-service-team{
        .card-row{
            h3{
                font-size: #{ (20 / 16) }rem;
                line-height: #{ (24 / 16) }rem;
                font-weight: bold;
            }
        }
    }
    .footer{
        a:not(.btn){
            color: $font-color-footer;
            font-size: #{ (14 / 16) }rem;
            line-height: #{ (17 / 16) }rem;
        }
        .copy-tm{
            font-size: #{ (12 / 16) }rem;
            line-height: #{ (15 / 16) }rem;
        }
        h4{
            color: $h1-color;
        }
        .heading-type-4{
            font-size: #{ (14 / 16) }rem;
            line-height: #{ (17 / 16) }rem;
        }
    }
}

.green-container {
    background-color: #f4fdfc;
}

.purple-container {
    background-color: #5076f1;
}

.headerContainer {
    padding: 50px 35px;
    
    @media (min-width: $min-width-sm) {
        background: url("./../../../../assets/images/case-study/Header-IMG@2x.png") top center no-repeat;
        background-size: cover;
        padding: 130px 0px 150px 0px;
        margin-bottom: 100px;
    }
}

.QuoteContainer {
    background: url("./../../../../assets/images/case-study/Website-quote-banner-mobile.png") no-repeat;
    background-size: cover;
    background-position: bottom right;
    background-color: #85C9E2;
    margin: 40px 0px;
    padding: 40px 35px 80px 35px;
    @media (min-width: $min-width-sm) {
        background: url("./../../../../assets/images/case-study/Website-quote-banner-1@2x.png") no-repeat;
        background-size: cover;
        background-position: top center;
        padding: 80px 0px;
        margin: 100px 0px;
    }
}

.QuoteContainer h3,
.QuoteContainer h6 {
    color: #ffffff !important;
}

.QuoteContainer h6 {
    border-left: 3px solid #68dec2;
    padding: 5px 0px 5px 25px;
    line-height: 32px;
    max-width: 230px;
}

.green-highlight {
    background-color: #8eeee0;
    padding: 0px 5px;
    border-radius: 5px;
}

.purple-container h1,
.purple-container p {
    color: #ffffff;
}

.ml-gm-page {
  /** lottie animation css **/
  // #lottie {
  //   background-color: transparent;
  //   width: 100%;
  //   height: 100%;
  //   display: grid;
  //   grid-template-columns: auto;
  //   overflow: hidden;
  //   transform: translate3d(0, 0, 0);
  //   text-align: center;
  //   opacity: 1;
  //   position: static;
  //   top: 0px;
  //   left: 0px;

  //   @media (max-width: $max-width-sm) {
  //     display: grid;
  //     grid-template-columns: auto auto;
  //     position: absolute;
  //     top: -260px;
  //     left: -70px;
  //     overflow: visible;
  //   }
  // }

  // #lottie-mobile {
  //   background-color: transparent;
  //   width: 70%;
  //   height: 70%;
  //   overflow: visible;
  //   transform: translate3d(0, 0, 0);
  //   text-align: center;
  //   opacity: 1;
  //   position: relative;
  //   top: -40px;
  //   left: -40px;
  //   display: none;

  //   @media (max-width: $max-width-sm) {
  //     display: block;
  //   }
  // }

  // .lottie-section-holder {
  //   position: relative;
  // }
  // .lottie-animation-holder {
  //   height: 1100px;
  //   width: 1100px;
  //   margin-top: -190px;
  //   margin-left: -100px;
  //   position: absolute;
  //   @media (max-width: $max-width-xl) {
  //     height: 850px;
  //     width: 850px;
  //     margin-top: -90px;
  //   }
  //   @media (max-width: $max-width-md) {
  //     height: 700px;
  //     width: 700px;
  //     margin-top: -30px;
  //   }
  //   @media (max-width: 959px) {
  //     height: 650px;
  //     width: 650px;
  //     margin-top: 110px;
  //   }
  //   @media (max-width: $max-width-sm) {
  //     position: static;
  //     height: 400px;
  //     width: 145%;
  //     margin: 0px;
  //     margin-top: -70px;
  //   }
  // }

  .header-section {
    background-image: url("../../images/banner-img-customer-service.jpg");
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat;
    background-color: #645DCB;

    &.ml-home-header-section{
      @media (min-width: $min-width-md) and (max-width: 1570px) {
        background-size: 90%;
        background-position: bottom right;
      }
      @media (max-width: $max-width-md) {
        background-color: #FFF;
        
        background-repeat: no-repeat;
        background-position: top right;
        padding: 340px 0px 20px;

        h1.heading-type-1{
          color: $h1-color;
        }
        p.p-type-1{
          color: $h1-color;  
        }
        .btn{
          margin-bottom: 20px;
        }
      }
      @media (max-width: 810px) {
        padding-top: 280px;
      }
      @media (max-width: $max-width-sm) {
        background-size: 150% auto;
        padding-top: 230px;
        .btn{
          margin-bottom: 0px;
        }
      }
    }
  }

  .header-section h1,
  .header-section p {
    color: #ffffff;
  }

  @media (max-width: $max-width-xl) {
    .gbs-image-section {
      width: 100% !important;
      height: auto !important;
      margin-left: 0px !important;
      .main-background {
        width: 100% !important;
      }
      .main-sheet2 {
        top: 21.2% !important;
        right: 5% !important;
        width: 19.2% !important;
      }
      .main-sheet1 {
        top: 35% !important;
        right: 10% !important;
        width: 20% !important;
      }
      .main-sheet {
        top: 17.9% !important;
        width: 35.9% !important;
        left: 32.7% !important;
      }
    }
    .edmAnimationSection {
      width: 100% !important;
      height: auto !important;
      .edm-img1 {
        width: 41.5% !important;
      }
      .edm-img2 {
        width: 40% !important;
        bottom: 7.6% !important;
        left: 27.5% !important;
      }
      .edm-img3 {
        width: 40% !important;
      }
    }

    #centralisedAnimation {
      width: 100% !important;
      height: auto !important;
      margin-left: 0px !important;
      #centralised-img1 {
        width: 90% !important;
      }
      #centralised-img2 {
        width: 67% !important;
      }
    }

    #lastAnimation {
      height: auto !important;
      width: 100% !important;
      .oval {
        width: 71.9% !important;
      }
      .msg-sheets1 {
        width: 58% !important;
      }
      .msg-sheets2 {
        width: 58% !important;
        top: 8.4% !important;
        left: 9.1% !important;
      }
      .msg-sheets3 {
        width: 58% !important;
        top: 17.4% !important;
        left: 18.9% !important;
      }
      .msg-sheets4 {
        width: 58% !important;
        top: 26% !important;
        left: 29% !important;
      }
    }

    @media (max-width: $max-width-md) {
      .edmAnimationSection {
        width: 100% !important;
        height: auto !important;
        .edm-img1 {
          width: 41.5% !important;
          left: 6%;
        }
        .edm-img2 {
          width: 40% !important;
          bottom: 7.6% !important;
          left: 27.5% !important;
        }
        .edm-img3 {
          width: 40% !important;
        }
      }

      .continer-show-dots.ars-container:before {
        top: -20px;
      }

      .carousel-indicators {
        bottom: 0px;
      }
      .carousel-indicators li {
        width: 9px;
        height: 9px;
        background: #ffffff;
        border: none;
        border-radius: 5px;
        border: 1px solid #8189a9;
      }
      .carousel-indicators .active {
        width: 9px;
        height: 9px;
        background: #8189a9;
      }
      .carousel-control-next,
      .carousel-control-prev {
        color: #000;
      }

      .carousel-control-prev {
        left: 0;
        background: #15c39a;
        height: 54px;
        top: 50%;
        // left: 8px;
        border-radius: 0px 40px 40px 0px;
        opacity: 1;
        width: 35px;

        .carousel-control-prev-icon {
          background-image: url("../../images/Chevron.svg");
          transform: rotate(180deg);
        }
      }

      .carousel-control-next {
        background: #15c39a;
        height: 54px;
        top: 50%;
        // right: 5px;
        border-radius: 40px 0px 0px 40px;
        opacity: 1;
        width: 35px;

        .carousel-control-next-icon {
          background-image: url("../../images/Chevron.svg");
        }
      }
    }
  }
  @media (max-width: $max-width-md) {
    .testimonial-1 {
      padding-top: 0px;
      margin-top: -70px;
    }
  }

  .banner-Btn {
    position: relative;
    z-index: 999;
  }

  // Form Styles
  .green-btn,
  .form-container input[type="submit"],
  .form-container input[type="button"] {
    padding: 14px 40px;
    height: 48px;
    border-radius: 25px;
    background-color: #15c39a;
    border: none;
    color: #ffffff;
    text-decoration: none;
  }

  .form-container {
    height: fit-content;
    width: 100%;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 2px 27px 0 rgba(129, 137, 169, 0.33);
    margin-top: 130px;
    @media (max-width: $max-width-sm) {
      margin-top: 30px;
    }
  }

  .header-lines {
    margin-right: -25%;
    max-width: 200px;
    margin-top: 175px;
  }

  .header-dots {
    height: 45px;
    margin-bottom: -45px;
    @media (max-width: $max-width-sm) {
      margin-top: 20px;
    }
  }

  .header-dots img {
    max-width: 150px;
    margin: 16px 0px 0px 20px;
  }

  .form-container .form-elements,
  .form-container .selectedBtn {
    display: none;
  }

  .form-container label {
    width: 100%;
    margin-top: 38px;
  }

  .form-container .btn-talk {
    width: auto !important;
  }

  .form-container p {
    color: #3b3e4d;
  }

  .form-container input,
  .form-container select,
  .form-container textarea {
    box-sizing: border-box;
    border: 1px solid rgba(129, 137, 169, 0.4);
    border-radius: 5px;
    width: 100%;
    height: 45px;
    margin-top: 15px;
    padding: 0px 0px 0px 10px;
  }

  .form-container input[type="submit"],
  .form-container input[type="button"] {
    width: auto;
    margin: 25px 0px 0px 0px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 0px 80px;
  }

  .Form-Intro-Container p {
    color: #3b3e4d;
    font-family: "AkkuratPro";
    font-size: 16px;
    line-height: 25px;
  }

  .customRadioBtn {
    display: none;
  }

  .customRadioBtn ~ label {
    padding: 10px 0px;
    border-radius: 5px;
    background-color: transparent;
    max-width: 90px;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #8189a9;
    display: inline-block;
    cursor: pointer;
    margin-right: 0px;
    margin-top: 0px !important;
    margin-bottom: 0px;
    box-sizing: border-box;
    border: 1px solid rgba(129, 137, 169, 0.4);

    @media (max-width: $max-width-sm) {
      margin-right: 0px;
      margin-top: 20px !important;
    }
  }

  .customRadioBtn:checked ~ label {
    background-color: #4c7af1;
    color: #ffffff;
    display: inline-block;
  }

  .form-container textarea {
    height: 150px !important;
    padding-top: 10px;
  }

  .Thankyou-Container img {
    height: 46px;
  }

  .soc2-section{
    padding: 80px 0px 80px;
    a{
      font-weight: bold;
      font-size: 16px;
      line-height: 32px;
      color: #4C7AF1;
    }
    
    a{
      margin-top: 40px;
    }
    @media (max-width: $max-width-sm) {
      img{
        margin-bottom: 40px;
      }
    }
  }

  .align-team-with-company-section{
    padding: 0px 0px 80px;

    h2{
      margin-bottom: 15px;
    }
    .sub-para{
      margin-bottom: 70px;
    }

    .content-section{
      p{
        text-align: left;
        padding-right: 10px;
        margin-bottom: 0px;
        margin-left: 15px;
      }
      .img-container{
        margin: 0px 15px;
        margin-bottom: 36px;
      }
      img{
        box-shadow: 0px 10.7786px 43.1143px rgba(109, 117, 141, 0.2);
        border-radius: 10px;
      }
    }
    .cta-link-section{
      margin-top: 55px;
    }

    @media (max-width: $max-width-sm) {
      padding: 0px 0px 60px;

      .content-section{
        > div{
          padding: 0px;
        }
        .img-container{
          margin-left: 0px;
          margin-right: 0px;
        }
        p{
          margin-left: 0px;
        }
      }
    }
  }
}

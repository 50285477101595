.ebook-landing-page{
    .section-grey {
        background-color: $color-grey;
    }

    .section-dark-grey {
        background-color: $color-dark-grey;
    }

    .heading-txt{
        margin-bottom: 20px;
    }

    h1 {
        font-size: #{ (35 / 16) }rem;
        line-height: #{ (41 / 16) }rem;
        color: $h1-color;

        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
            font-size: #{ (45 / 16) }rem;
            line-height: #{ (52 / 16) }rem;
        }
    }

    h2 {
        font-size: #{ (25 / 16) }rem;
        line-height: #{ (30 / 16) }rem;
        color: $h2-color;

        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
            font-size: #{ (30 / 16) }rem;
            line-height: #{ (52 / 16) }rem;
        }
    }

    .font-subheader{
        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
            line-height: #{ (36 / 16) }rem;
        }
    }

    h3 {
        font-size: #{ (18 / 16) }rem;
        line-height: #{ (22 / 16) }rem;

        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
            font-size: #{ (20 / 16) }rem;
            line-height: #{ (24 / 16) }rem;
        }
    }

    .text-big {
        font-size: #{ (18 / 16) }rem;
        line-height: #{ (26 / 16) }rem;

        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
            font-size: #{ (18 / 16) }rem;
            line-height: #{ (29 / 16) }rem;
        }
    }

    .text-normal {
        font-size: #{ (15 / 16) }rem;
        line-height: #{ (23 / 16) }rem;

        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
            font-size: #{ (16 / 16) }rem;
            line-height: #{ (25 / 16) }rem;
        }
    }

    .hero-cover {

        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
            position: absolute;
            bottom: 0;
            left: 0;
        }

    }
    .hero-cover-static{
        position: static;
    }

    .text-small {
        font-size: #{ (12 / 16) }rem;
        line-height: #{ (15 / 16) }rem;
    }

    .footer {
        a:not(.btn) {
            font-size: #{ (12 / 16) }rem !important;
            line-height: #{ (15 / 16) }rem;
        }
    }


    /*
     *****************************
     * * * * * * * * * * * * * * *
     *        Texte color        *
     * * * * * * * * * * * * * * *
     *****************************
     */

    .text-color-grey {
        color: $font-color-footer;
    }

    /********Form Styles*********/
    // .form-container {
    //     height: fit-content;
    //     width: 100%;
    // }

    .form-container .sources-text {
        color: #ffffff;
    }

    .form-container .form-elements,
    .form-container .selectedBtn {
        display: block;
    }

    .form-container label {
        width: 100%;
        margin-top: 38px;
    }

    .form-container .btn-talk {
        width: fit-content !important;
        border-radius: 15px;
    }

    .form-container input,
    .form-container select,
    .form-container textarea {
        box-sizing: border-box;
        border: 1px solid rgba(129, 137, 169, 0.4);
        border-radius: 5px;
        width: 100%;
        height: 45px;
        margin-top: 15px;
        padding: 0px 0px 0px 10px;
    }

    .form-container input[type="submit"],
    .form-container input[type="button"] {
        width: auto;
        margin: 25px 0px 0px 0px;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        padding: 0px 80px;
    }

}

.hackingcx2-ebook-landing-page{
    @media (min-width: $min-width-sm ) and ( max-width: $max-width-md) {
        img.hero-cover{
            max-width: 450px;
            margin-top: 30px;
        }
    }   
}

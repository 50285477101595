.blog-resources-container{
    @media (min-width: 1140px){
        .container {
            max-width: 1140px;
        }
    }

    .banner-section{
        background-image: url("./../../images/banner-img-resource-list.png");  
    }

    .main-content{
        padding: 80px 0px;
    }
    
    .content-box-custom-container{
        margin-bottom: 20px;
    }

    img.green-cross-icon{
        width: 40px;
    }
  
    .green-blue-circle{
        position: relative;
        &:before{
            content: "";
            width: 138px;
            height: 138px;
            position: absolute;
            background-image: url("./../../images/green-blue-rings.png");
            background-size: contain;
            background-repeat: no-repeat;
            bottom: -77px;
            right: -48px;
        }
    }    

    .sort-section{
        margin-bottom: 30px;
        
        .btn.dropdown-toggle{
            min-width:138px;
            width: 100%;
            border-radius: 3px;
            background-color: $color-grey;
            position: relative;
            padding: 12px 20px;
            text-align: left;

            &:focus{
                box-shadow: none;
            }
            &:after{
                font-size: 20px;
                right: 20px;
                position: absolute;
                margin-top: 3px;
            }
        }
    }

    .pagination-container{
        margin-top: 60px;
    }
    

    @media (min-width: $min-width-md) {
        .banner-section{
            h1{
                width: 56%;
            }
        }
    }
    @media (max-width: $max-width-sm) {
        overflow-y: auto;
        .banner-section{
            p{
                margin-bottom: 0px;
            }
            .container{
                padding-left: 30px;
                padding-right: 30px;
            }
        }
        .main-content{
            padding: 40px 0px 50px;
            overflow: hidden;

            .container{
                padding-left: 0px;
                padding-right: 0px;
            }

            .lm-container{
                padding-left: 35px;
                padding-right: 35px;
                padding-top: 20px;

                .btn-cta{
                    max-width: 224px;
                }
            }
        }

        .content-box-custom-container{
            margin-bottom: 40px;
        }

        .option-sidebar-container{
            max-width: 100%;
            height: 100vh;
            z-index: 99;
            position: fixed;
            top: 0;
            overflow: auto;

            &.green-blue-circle:before{
                content: none;
            }
        }

    }
}
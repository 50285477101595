.section-grey {
  background-color: $color-grey;
}
.section-dark-grey {
  background-color: $color-dark-grey;
}
.container{
  @media (max-width: $max-width-xl) {
    padding-left: 35px;
    padding-right: 35px;
  }
}
.citation{
  padding-bottom: 20px;
  a{
      color: $font-color;
  }
}

.content-box-custom{
  border-radius: 5px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 27px 0 rgba(129,137,169,0.33);
  padding: 30px;
  margin-bottom: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .bb-img{
    margin-bottom: 20px; 
  }
  .bb-img-container{
    margin-bottom: 20px;
    height: 170px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }

  p{
    margin-bottom: 0px;
  }

  .title{
    margin-bottom: 15px;
  }
  .content{
    margin-bottom: 10px; 
  }
  .author{
    margin-bottom: 20px; 
  }
  .publish-info{
    margin-bottom: 20px; 
  }
  .cta-link{
    border: none;
    background: none;
    padding: 0px;

    &:focus{
      outline: none;
    }

    .download-icon{
      width: 25px;
    }
  }
}

.pagination-container{
  
  .pagination{
    margin-bottom: 0px;
    
    
    a{
      color: $h1-color;
      box-shadow: none;
    }

    .page-link{
      cursor: pointer;
      border: none;
      &:hover{
        background: none;
      }
      min-width: 28px;
      padding: 3px;
      text-align: center;
    }

    .nav-control{
      a{
        color: $button-background-color;
      }
    }
    li{
      margin: 0px 3px;
    }
    .active {
      a{
        color: $color-grey;
        background-color: $button-background-color;
        border-radius: 100%;
        &:hover{
          background-color: $button-background-color;
        }
      }
    }
    .prev-container{
      margin-right: 10px;
    }
    .next-container{
      margin-left: 10px;
    }
    
  }
}

.dropdown-menu {
  a{
    cursor: pointer;
  }
}

/** option sidebar css**/
.option-sidebar-container{
  max-width: 290px;
  width: 100%;
  border-radius: 8px;
  background-color: $color-grey;
  padding: 35px 30px;
  
  .filter-title-section{
      margin-bottom: 30px;
  }

  .filter-section{
      .form-check{
          margin-bottom: 20px;
          &:last-child{
              margin-bottom: 0px;
          }   
      }
  }

  .filter-section-1{
      margin-bottom: 20px;
  }

  h4{
      margin-bottom: 25px;
  }

  .mob-reset-control-section{
    margin-top: 40px;

    .btn-cta{
      color: $color-white;
      max-width: 185px;
      width: 100%;
    }
  }

  @media (max-width: $max-width-sm) {
    max-width: 100%;
  }
}
.mobile-sticky-sort-option-container{
  padding: 25px 5px;
  background: $color-grey;
  text-align: center;
  z-index: 9;
  position: fixed;
  bottom: 0;

  .dropdown-menu{
    width: 100%;
    top: -115px;
    padding: 20px 0px;

    a{
      margin-bottom: 5px;
    }
  }
}

/** checkbox custom **/
.input-checkbox-custom{
  cursor: pointer;

  span{
    padding-left: 46px;
    display: block;
  }

  [type="checkbox"] {
    margin-right: 46px;
    margin-top: 0px;
    margin-left: 0px;

    &:not(:checked):before,
    &:checked:before {
      content: "";
      position: absolute;
      left: -1px;
      top: -1px;
      height: 26px;
      width: 26px;
      border: 1px solid #8189A9;
      border-radius: 3px;
      background: $color-white;
      box-sizing: border-box;
      cursor: pointer;
    }
    &:not(:checked):after {
      opacity: 0;
      transform: scale(0);
    }
  }

  [type="checkbox"]
  [type="checkbox"]:not(:checked):after,
  [type="checkbox"]:checked:after {
    position: absolute;
    content: "";
    left: 9px;
    top: 1.6px;
    width: 8px;
    height: 16px;
    border: solid #012a4d;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-color: #4C7AF1!important;
  }
} 

/** modal youtube custom **/
.modal-vids-custom{
  z-index: 99999;
  .modal-dialog{
    max-width: 800px;
    background-color: transparent;
    margin-top: 80px;
  }
  .modal-content{
    padding: 0px;
    background: transparent;
    border: none;
  }
  .modal-body{
    padding: 0px;
    background: transparent;
  }

  .close{
    opacity: 1;
    color: #FFF;

    &:focus{
      outline: none;
    }

    img{
      width: 20px;
      margin-bottom: 20px;
    }
  }
  @media (max-width: $max-width-md) {
    .modal-dialog{
      width: 90%;
    }
  }
  @media (max-width: $max-width-sm) {
    .modal-dialog{
      margin: 60px 15px;
      width: auto;
    }
    iframe{
      height: 320px;
    }
  }
}
.modal-sh-vids-custom{
  .close{
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 999;
    cursor: pointer;
  }
  video{
    max-width: 800px;
    width: 100%;
  }
} 
.modal-vids-backdrop-custom{
  z-index: 9999;
}

.share-icon-float-container {
  position: absolute;
  left: -62px;

  .share-icon{
      background-color: #F1F2FC;
      border-radius: 50px;
      cursor: pointer;
      margin-bottom: 20px;
  }

  @media (max-width: $max-width-xl) {
    left: -50px;  
  }
}

.rld-form-custom #first_name{
  margin-top: 0px;
}






/** why service your team **/
.why-service-team{
    padding: 92px 0px 67px;
    @media (max-width: $max-width-sm) {
      padding-bottom: 0px;
    }

    position: relative;
    .container-circle{
      &:before{
        content: "";
        width: 93px;
        height: 93px;
        background: url("../../images/Circles.png");
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        top: -30px;
        margin-left: 40px;
        @media (max-width: $max-width-md) {
          width: 70px;
          height: 70px;
          top: -20px;
          margin-left: 20px;
        }
      }
    }
    h2{
      margin-bottom: 47px;
      color: $h1-color;
    }
    .card-row{
      &>div{
        margin-bottom: 38px;
        &:last-child{
          margin-bottom: 0px;
        }
      }
      margin-bottom: 46px;
      @media (max-width: $max-width-md) {
        margin-bottom: 0px;
      }
      img{
        height: 64px;
        margin-bottom: 24px;
      }
      h3{
        margin-bottom: 10px;
      }
    }
}

.sb-custom section{
  padding-bottom: 38px;
  padding-top: 38px;
  background-color: $color-white;
}
